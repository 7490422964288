import React, { useState } from "react";
import { StoredDataset } from "@services/space/datasets/models";
import { SelectedDataset } from "./selected-dataset";

export interface SelectedDatasetsContainerProps {
    selectedDatasets: StoredDataset[];
    isLoading?: boolean;
    handleDismissDataset: (fileName: string) => void;
    handleDatasetReorder: (updatedDatasets: StoredDataset[]) => void;
    testId?: string;
}

export function SelectedDatasetsContainer({
    selectedDatasets,
    isLoading,
    handleDismissDataset,
    handleDatasetReorder,
    testId
}: SelectedDatasetsContainerProps) {
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(
        null
    );

    const handleDragStart = (
        event: React.DragEvent<HTMLLIElement>,
        index: number
    ) => {
        setDraggedItemIndex(index);
        event.dataTransfer.effectAllowed = "move";
        document.body.classList.add("dragging");
    };

    const handleDragOver = (
        event: React.DragEvent<HTMLLIElement>,
        index: number
    ) => {
        event.preventDefault();
        if (draggedItemIndex !== null && draggedItemIndex !== index) {
            const updatedDatasets = [...selectedDatasets];
            const [draggedItem] = updatedDatasets.splice(draggedItemIndex, 1);
            updatedDatasets.splice(index, 0, draggedItem);
            handleDatasetReorder(updatedDatasets);
            setDraggedItemIndex(index);
        }
    };

    const handleDragEnd = () => {
        setDraggedItemIndex(null);
        document.body.classList.remove("dragging");
    };

    const selectedDatasetsContainerCn = isLoading
        ? "loading selected-datasets"
        : "selected-datasets";

    const noSelectedDatasetsTestId = testId
        ? `${testId}-no-selected-datasets`
        : undefined;

    return (
        <div className="selected-datasets-container-wrapper">
            <ul className={selectedDatasetsContainerCn} data-testid={testId}>
                {selectedDatasets.length > 0 ? (
                    <>
                        {selectedDatasets.map((dataset, index) => (
                            <React.Fragment key={dataset.fileName}>
                                <SelectedDataset
                                    dataset={dataset.fileName}
                                    handleDismissDataset={handleDismissDataset}
                                    handleDragStart={(event) =>
                                        handleDragStart(event, index)
                                    }
                                    handleDragOver={(event) =>
                                        handleDragOver(event, index)
                                    }
                                    handleDragEnd={handleDragEnd}
                                    className={
                                        draggedItemIndex === index
                                            ? "dragging"
                                            : ""
                                    }
                                    testId={testId}
                                />
                            </React.Fragment>
                        ))}
                    </>
                ) : (
                    <div
                        className="no-datasets"
                        data-testid={noSelectedDatasetsTestId}
                    >
                        <div className="no-datasets-message">
                            <div className="top-message">
                                No Datasets Selected
                            </div>
                            <div className="bottom-message">
                                Please choose your datasets.
                            </div>
                        </div>
                    </div>
                )}
            </ul>
        </div>
    );
}
