type Color = {
    name: string;
    hexValue: string;
};

const Colors: Color[] = [
    { name: "blue", hexValue: "#0000FF" },
    { name: "red", hexValue: "#FF0000" },
    { name: "green", hexValue: "#008000" },
    { name: "fuchsia", hexValue: "#FF00FF" },
    { name: "navy", hexValue: "#000080" },
    { name: "purple", hexValue: "#800080" },
    { name: "lime", hexValue: "#00FF00" },
    { name: "maroon", hexValue: "#800000" },
    { name: "aqua", hexValue: "#00FFFF" },
    { name: "olive", hexValue: "#808000" },
    { name: "black", hexValue: "#000000" }
];

export default Colors;
