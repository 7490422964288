// API Response
import { ApiResponse, ApiErrorResponse } from "./api-response";
export type { ApiResponse, ApiErrorResponse };

// Service Locator
import ServiceLocator from "./service-locator";
export { ServiceLocator };

// Personalization
import {
    PersonalizationService,
    PersonalizationRecord,
    ServiceKey as PersonalizationServiceKey
} from "./personalization";
export type { PersonalizationService, PersonalizationRecord };
export { PersonalizationServiceKey };

// utilities
export const AuthServiceKey = "Authentication";

// Datasets
import { DatasetServiceImplementation } from "./space/datasets/implementations";

export { DatasetServiceImplementation };

// Dataset Formats
import {
    initialDataSetsFormats,
    DatasetFormatsService,
    DatasetConvertService,
    ServiceKey as SpaceServiceKey
} from "./space/datasets/interface";

export { initialDataSetsFormats, SpaceServiceKey };
export type { DatasetFormatsService };

// Dataset Convert
export type { DatasetConvertService };
