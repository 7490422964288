import React from "react";

export default function LogoIcon(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="28"
            viewBox="0 0 209 173"
            fill="none"
        >
            <path
                d="M29.9014 38.049C33.7073 38.049 36.7899 34.9664 36.7899 31.1605C36.7899 27.3546 33.7073 24.272 29.9014 24.272C26.0955 24.272 23.0129 27.3546 23.0129 31.1605C23.0129 34.9664 26.0955 38.049 29.9014 38.049Z"
                fill="url(#paint0_linear_1591_5015)"
            />{" "}
            <path
                d="M43.7495 30.1904C41.2144 30.1904 39.156 32.2455 39.156 34.7839C39.156 37.3223 41.2111 39.3773 43.7495 39.3773C46.2879 39.3773 48.3429 37.3223 48.3429 34.7839C48.3429 32.2455 46.2879 30.1904 43.7495 30.1904Z"
                fill="url(#paint1_linear_1591_5015)"
            />
            <path
                d="M39.1593 22.9639C45.5006 22.9639 50.6413 17.8233 50.6413 11.482C50.6413 5.14065 45.5006 0 39.1593 0C32.818 0 27.6774 5.14065 27.6774 11.482C27.6774 17.8233 32.818 22.9639 39.1593 22.9639Z"
                fill="url(#paint2_linear_1591_5015)"
            />
            <path
                d="M207.701 46.9959C203.331 39.428 181.144 43.3454 151.055 55.5135C153.245 57.5145 155.3 59.6541 157.217 61.9187C163.909 60.4146 168.33 60.5903 169.588 62.7705C170.774 64.8289 169.017 68.4253 164.92 73.0525C164.031 74.0564 163.031 75.1076 161.929 76.2027C159.86 72.4407 157.393 68.8309 154.516 65.4272C134.841 42.1489 102.393 36.0446 76.1571 48.7062C76.0929 48.7366 76.0185 48.7197 75.9746 48.6656L64.5163 35.1117L69.3328 31.0388L64.3676 25.1677L52.8383 34.9123L57.7866 40.7665L72.4526 58.1162C75.5859 61.8241 81.1291 62.2871 84.837 59.1538H84.8404C108.511 49.5343 137.366 58.1567 150.937 83.3075C151.258 83.9024 151.562 84.5007 151.86 85.1023C140.945 93.9242 125.827 104.186 108.531 114.171C91.1103 124.23 74.5482 132.237 61.4067 137.27C49.5326 120.011 49.3163 97.605 59.9127 80.3128C59.9228 80.2993 59.933 80.2858 59.9465 80.2756C63.6442 77.1525 64.1073 71.6228 60.9808 67.925L46.2979 50.5584C46.2979 50.5584 46.2777 50.5415 46.2675 50.5348H46.2743L41.309 44.6569L29.7797 54.4015L34.745 60.2726L39.5683 56.1963C39.5683 56.1963 39.5785 56.22 39.5852 56.2301L51.0469 69.7874C51.0908 69.8415 51.0942 69.9192 51.0536 69.9733C36.2255 90.9971 35.0323 119.21 48.5727 141.525C47.2139 141.887 45.9261 142.194 44.7262 142.438C38.6726 143.671 34.6774 143.398 33.491 141.339C32.2337 139.162 34.2819 135.252 38.9193 130.219C37.9222 127.447 37.0975 124.591 36.4553 121.667C10.8854 141.64 -3.59803 158.892 0.772349 166.46C5.14272 174.031 27.3326 170.11 57.4216 157.945C58.6046 157.469 59.7978 156.975 61.0044 156.472C85.1852 178.029 122.578 178.864 147.709 156.823C165.985 140.792 173.228 116.733 168.625 94.394C169.774 93.5186 170.906 92.6499 172.011 91.7846C197.585 71.812 212.068 54.5604 207.698 46.9891L207.701 46.9959ZM146.026 139.663C127.736 162.846 96.3257 165.834 74.5178 150.54C87.8283 144.402 102.15 136.992 116.815 128.522C131.414 120.092 144.941 111.433 156.876 103.013C158.055 115.928 154.351 129.114 146.029 139.663H146.026Z"
                fill="url(#paint3_linear_1591_5015)"
            />
            <path
                d="M134.065 67.3752L56.1696 112.348L58.1536 115.785L136.049 70.8117L134.065 67.3752Z"
                fill="url(#paint4_linear_1591_5015)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1591_5015"
                    x1="27.5422"
                    y1="37.6366"
                    x2="32.2573"
                    y2="24.6877"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3340" />
                    <stop offset="0.13" stopColor="#C52C50" />
                    <stop offset="0.37" stopColor="#80226B" />
                    <stop offset="0.58" stopColor="#491A81" />
                    <stop offset="0.77" stopColor="#211491" />
                    <stop offset="0.91" stopColor="#08109B" />
                    <stop offset="1" stopColor="#000F9F" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1591_5015"
                    x1="42.1811"
                    y1="39.1002"
                    x2="45.3212"
                    y2="30.4676"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3340" />
                    <stop offset="0.13" stopColor="#C52C50" />
                    <stop offset="0.37" stopColor="#80226B" />
                    <stop offset="0.58" stopColor="#491A81" />
                    <stop offset="0.77" stopColor="#211491" />
                    <stop offset="0.91" stopColor="#08109B" />
                    <stop offset="1" stopColor="#000F9F" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1591_5015"
                    x1="35.2317"
                    y1="22.2744"
                    x2="43.0869"
                    y2="0.689526"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3340" />
                    <stop offset="0.13" stopColor="#C52C50" />
                    <stop offset="0.37" stopColor="#80226B" />
                    <stop offset="0.58" stopColor="#491A81" />
                    <stop offset="0.77" stopColor="#211491" />
                    <stop offset="0.91" stopColor="#08109B" />
                    <stop offset="1" stopColor="#000F9F" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1591_5015"
                    x1="68.3695"
                    y1="192.597"
                    x2="131.955"
                    y2="17.9006"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3340" />
                    <stop offset="0.13" stopColor="#C52C50" />
                    <stop offset="0.37" stopColor="#80226B" />
                    <stop offset="0.58" stopColor="#491A81" />
                    <stop offset="0.77" stopColor="#211491" />
                    <stop offset="0.91" stopColor="#08109B" />
                    <stop offset="1" stopColor="#000F9F" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1591_5015"
                    x1="83.8934"
                    y1="125.153"
                    x2="108.331"
                    y2="58.0052"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3340" />
                    <stop offset="0.13" stopColor="#C52C50" />
                    <stop offset="0.37" stopColor="#80226B" />
                    <stop offset="0.58" stopColor="#491A81" />
                    <stop offset="0.77" stopColor="#211491" />
                    <stop offset="0.91" stopColor="#08109B" />
                    <stop offset="1" stopColor="#000F9F" />
                </linearGradient>
            </defs>
        </svg>
    );
}
