import {
    AnalysisParameters,
    CyResinTypes
} from "../../../interfaces/analysis-types";
import {
    RheologyAnalysisRequest,
    BaseAnalysisRequest
} from "@services/space/analysis/models";
import { StoredDataset } from "@services/space/datasets/models";

const resinTypeDictionary: { [key: string]: string } = {
    Other: "OTH",
    PE: "PE",
    PP: "PP",
    PMP: "PMP",
    PS: "PS"
};

const getMetricIndex = (
    metrics: { identifier: string | null }[],
    targetIdentifier: string
): number => {
    return metrics.findIndex(
        (metric) => metric.identifier === targetIdentifier
    );
};

const buildRheologyAnalysisRequest = (
    analysisParams: AnalysisParameters
): RheologyAnalysisRequest => {
    const isTTS = analysisParams.cyRheologyParams?.dataType === "tempFreqSweep";
    return {
        analysisType: isTTS ? "cyRheologyTTS" : "cyRheology",
        applyYieldStressTerm:
            analysisParams.cyRheologyParams?.isUsingYieldStressTerm || false,
        fixedN: parseFloat(
            analysisParams.cyRheologyParams?.fixN
                ? analysisParams.cyRheologyParams?.nValue || "0"
                : "0"
        ),
        samples:
            analysisParams.cyRheologyParams?.selectedDatasets?.map(
                (dataset: StoredDataset) => {
                    const name =
                        (dataset.metadata
                            .find((meta) =>
                                meta.attributes.some(
                                    (attr) => attr.identifier === "sample_name"
                                )
                            )
                            ?.attributes.find(
                                (attr) => attr.identifier === "sample_name"
                            )?.value as string) || dataset.fileName;
                    const requestedBy =
                        (dataset.metadata
                            .find((meta) =>
                                meta.attributes.some(
                                    (attr) =>
                                        attr.identifier ===
                                        "sample_requested_by"
                                )
                            )
                            ?.attributes.find(
                                (attr) =>
                                    attr.identifier === "sample_requested_by"
                            )?.value as string) || "";
                    const resinTypeValue = analysisParams.cyRheologyParams
                        ?.resinType as CyResinTypes;
                    const resinType =
                        resinTypeDictionary[resinTypeValue] ||
                        resinTypeDictionary.Other;

                    const { fileName } = dataset;

                    const referenceTemperature = Number(
                        analysisParams.cyRheologyParams?.referenceTemp
                    );

                    const vogelTemperature = Number(
                        analysisParams.cyRheologyParams?.vogelTemp
                    );

                    const temperatureSeries = dataset.series.find((series) =>
                        series.metrics.some(
                            (metric) => metric.identifier === "temperature"
                        )
                    );

                    const frequencySeries = dataset.series.find((series) =>
                        series.metrics.some(
                            (metric) =>
                                metric.identifier === "angular_frequency"
                        )
                    );
                    const viscositySeries = dataset.series.find((series) =>
                        series.metrics.some(
                            (metric) =>
                                metric.identifier === "complex_shear_viscosity"
                        )
                    );

                    const shearStorageModulusSeries = dataset.series.find(
                        (series) =>
                            series.metrics.some(
                                (metric) =>
                                    metric.identifier ===
                                    "shear_storage_modulus"
                            )
                    );

                    const shearLossModulusSeries = dataset.series.find(
                        (series) =>
                            series.metrics.some(
                                (metric) =>
                                    metric.identifier === "shear_loss_modulus"
                            )
                    );

                    const temperatureIndex = getMetricIndex(
                        temperatureSeries?.metrics || [],
                        "temperature"
                    );

                    const angularFrequencyIndex = getMetricIndex(
                        frequencySeries?.metrics || [],
                        "angular_frequency"
                    );
                    const viscosityIndex = getMetricIndex(
                        viscositySeries?.metrics || [],
                        "complex_shear_viscosity"
                    );
                    const storageIndex = getMetricIndex(
                        viscositySeries?.metrics || [],
                        "shear_storage_modulus"
                    );
                    const lossIndex = getMetricIndex(
                        viscositySeries?.metrics || [],
                        "shear_loss_modulus"
                    );

                    const temperature = temperatureSeries?.dataPoints.map(
                        (point) => parseFloat(point[temperatureIndex] as string)
                    );

                    const angularFrequency =
                        frequencySeries?.dataPoints.map((point) =>
                            parseFloat(point[angularFrequencyIndex] as string)
                        ) || [];

                    const complexShearViscosity =
                        viscositySeries?.dataPoints.map((point) =>
                            parseFloat(point[viscosityIndex] as string)
                        ) || [];

                    const shearStorageModulus =
                        shearStorageModulusSeries?.dataPoints.map((point) =>
                            parseFloat(point[storageIndex] as string)
                        ) || [];

                    const shearLossModulus =
                        shearLossModulusSeries?.dataPoints.map((point) =>
                            parseFloat(point[lossIndex] as string)
                        ) || [];

                    return {
                        name,
                        requestedBy,
                        resinType,
                        fileName,
                        referenceTemperature,
                        vogelTemperature,
                        measurements: {
                            temperature,
                            angularFrequency,
                            complexShearViscosity,
                            shearStorageModulus,
                            shearLossModulus
                        }
                    };
                }
            ) || []
    };
};

export const buildAnalysisRequest = (
    analysisParams: AnalysisParameters
): BaseAnalysisRequest => {
    switch (analysisParams.analysisType) {
        case "cyRheology":
            return buildRheologyAnalysisRequest(analysisParams);
        // Add more cases for other analysis types here
        default:
            throw new Error(
                `Unsupported analysis type: ${analysisParams.analysisType}`
            );
    }
};
