import React, { useEffect, useState } from "react";
import { AppBar, Button } from "@cpchem/covalence-ui";
import { useNavigate } from "react-router-dom";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { AuthManager } from "@cpchem/azure-auth";
import { getGraphUserPhoto } from "./methods/graph-photo";
import { getInitialsFromName } from "./methods/user-initials";
import { useAuth } from "../../hooks/use-auth";
import { getAvatarContent } from "./methods/avatar-content";
import { isInDevEnvironment } from "../../logging/in-dev-environment";
import config from "@config";
import { LogoIcon } from "../icons";

export interface SpaceAppBarProps {
    className?: string;
    testId?: string;
}

export function SpaceAppBar({
    className,
    testId
}: SpaceAppBarProps): JSX.Element {
    const cnParts = ["space-app-bar is-thick"];
    const navigate = useNavigate();
    const { currentAccount } = useAuth();
    const authMgr = AuthManager.getInstance();
    const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
    const [userInitials, setUserInitials] = useState<string>("");

    function handleIconClick() {
        navigate("/");
    }

    async function loadCurrentUserPhoto() {
        if (profilePhoto === null) {
            setProfilePhoto(await getGraphUserPhoto(profilePhoto));
        }
    }

    function loadUserInitials() {
        if (userInitials === "") {
            const parsedInitials = getInitialsFromName(currentAccount);
            if (parsedInitials !== userInitials) {
                setUserInitials(parsedInitials);
            }
        }
    }

    if (className) {
        cnParts.push(className);
    }

    const helpMenuContent = (
        <div className="feed-back-menu">
            <Button
                text={"Submit Feedback"}
                icon={
                    <FontAwesomeIcon icon={faExternalLink} className="icon" />
                }
                isIconAfterText
                variant="text"
            />
        </div>
    );

    useEffect(() => {
        loadUserInitials();
        if (
            (isInDevEnvironment(config.env) && config.connected) ||
            !isInDevEnvironment(config.env)
        ) {
            loadCurrentUserPhoto();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cn = cnParts.join(" ");
    return (
        <AppBar
            className={cn}
            icon={<LogoIcon />}
            onIconClick={handleIconClick}
            applicationName="SPACE"
            testId={testId}
            helpMenuItems={[
                {
                    key: "feedback",
                    content: helpMenuContent,
                    onClick: () =>
                        window.open(
                            "https://forms.office.com/pages/responsepage.aspx?id=e8e2iOD0xUCfu99RqScXmgXvML9bw0NMjls2h4nJ_ttUODRZNVFQWFUwOUhXSzFYWDlQTUdGNzg2SS4u&route=shorturl",
                            "_blank"
                        )
                }
            ]}
            avatarMenu={{
                avatar: getAvatarContent(
                    profilePhoto,
                    userInitials,
                    currentAccount,
                    testId
                ),
                userName: currentAccount ? currentAccount?.username : "",
                displayName: currentAccount ? currentAccount?.name : "",
                showLogout: true,
                showThemeSelector: false,
                onRequestLogout: authMgr.logout
            }}
        />
    );
}
