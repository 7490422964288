import { StoredDataset } from "@services/space/datasets/models";
import { AnalysisParameters } from "../../interfaces/analysis-types";

export const localStorageService = {
    setCurrentUserId: (userId: string) => {
        localStorage.setItem("currentUserId", userId);
    },

    getCurrentUserId: (): string | null => {
        return localStorage.getItem("currentUserId");
    },

    saveDatasetsForUser: (userId: string, datasets: StoredDataset[]) => {
        localStorage.setItem(`datasets_${userId}`, JSON.stringify(datasets));
    },

    saveAnalysisParametersForUser: (
        userId: string,
        analysisParameters: AnalysisParameters
    ) => {
        localStorage.setItem(
            `report_parameters_${userId}`,
            JSON.stringify(analysisParameters)
        );
    },

    loadDatasetsForUser: (userId: string): StoredDataset[] | null => {
        const currentUserIdKey = `datasets_${userId}`;
        const data = localStorage.getItem(currentUserIdKey);
        return data ? JSON.parse(data) : null;
    },

    loadAnalysisParametersForUser: (
        userId: string
    ): AnalysisParameters | null => {
        const currentUserIdKey = `report_parameters_${userId}`;
        const reportParameters = localStorage.getItem(currentUserIdKey);
        return reportParameters ? JSON.parse(reportParameters) : null;
    },

    clearDatasetsForUser: (userId: string) => {
        localStorage.removeItem(`datasets_${userId}`);
    },

    clearAnalysisParametersForUser: (userId: string) => {
        localStorage.removeItem(`report_parameters_${userId}`);
    },

    removeDatasetForUser: (userId: string, fileName: string) => {
        const datasets = localStorageService.loadDatasetsForUser(userId) || [];
        const filteredDatasets = datasets.filter(
            (dataset) => dataset.fileName !== fileName
        );
        localStorageService.saveDatasetsForUser(userId, filteredDatasets);
    },

    removeDatasetsForUser: (userId: string, fileNames: string[]) => {
        const datasets = localStorageService.loadDatasetsForUser(userId) || [];
        const filteredDatasets = datasets.filter(
            (dataset) => !fileNames.includes(dataset.fileName)
        );
        localStorageService.saveDatasetsForUser(userId, filteredDatasets);
    },

    clearAllDatasets: () => {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
            if (key.startsWith("datasets_")) {
                localStorage.removeItem(key);
            }
        });
    }
};
