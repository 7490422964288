import { StoredDataset } from "@services/space/datasets/models";

export type AnalysisType = "cyRheology" | "other";

export type CyResinTypes = "PE" | "PP" | "PMP" | "PS" | "Other";
export type CyDataTypes = "frequencySweep" | "tempFreqSweep";

export interface BaseAnalysisParameters {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface AnalysisParameters {
    analysisType: AnalysisType;
    /**
     * Please make sure to name any future analysis parameters in the format of `analysisTypeParams`
     * this is used in the useAnalysisParametersHandlers file to determine the key of the type of analysis
     * to update.
     */
    cyRheologyParams?: CyRheologyParameters;
}

export interface CyRheologyParameters extends BaseAnalysisParameters {
    selectedDatasets: StoredDataset[];
    dataType: CyDataTypes;
    fixN: boolean;
    nValue: string | null;
    vogelTemp: string;
    referenceTemp: string;
    isComparingDatasets: boolean;
    selectedCompareDataset: string | null;
    isUsingYieldStressTerm: boolean;
    resinType: CyResinTypes;
}

export const defaultCyRheologyParameters: CyRheologyParameters = {
    selectedDatasets: [],
    dataType: "frequencySweep",
    fixN: true,
    nValue: "0.1818",
    vogelTemp: "0.0",
    referenceTemp: "190.0",
    isComparingDatasets: false,
    selectedCompareDataset: null,
    isUsingYieldStressTerm: false,
    resinType: "PE"
};

export const defaultAnalysisParameters: AnalysisParameters = {
    analysisType: "cyRheology",
    cyRheologyParams: defaultCyRheologyParameters
};
