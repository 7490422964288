import { LogEvent, trackEvent } from "@cpchem/logging";
import { isInDevEnvironment, isInProduction } from "./in-dev-environment";
import config from "@config";

export function spaceTrackEvent(event: LogEvent) {
    if (
        (isInDevEnvironment(config.env) && config.connected) ||
        isInProduction(config.env)
    ) {
        trackEvent(event);
    }
}
