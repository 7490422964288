import React from "react";
import PreviewChart from "./preview-chart";
import { PlotData } from "plotly.js";
import { AnalysisResponseType } from "@stores/datasets/datasets-types";
import Colors from "../../../utilities/constants/chart-colors";
import { spaceTrackEvent } from "../../../logging/space-track-event";
import { LOADING_USER_REPORT } from "../../../logging/log-events";

export interface IPreviewChartDataProviderProps {
    RawData: AnalysisResponseType;
    Temp: string;
    testId?: string;
}

const PreviewChartDataProvider: React.FC<IPreviewChartDataProviderProps> = (
    props: IPreviewChartDataProviderProps
) => {
    type StyleAssignment = {
        color: string;
        symbol: string;
        dash: string;
    };

    const { RawData, Temp, testId } = props;

    const getColorValue = (name: string): string => {
        return Colors.find((color) => color.name === name)?.hexValue ?? "";
    };

    const styleAssignment: StyleAssignment[] = [
        { color: getColorValue("blue"), symbol: "circle", dash: "solid" },
        { color: getColorValue("red"), symbol: "triangle-up", dash: "dash" },
        {
            color: getColorValue("green"),
            symbol: "square",
            dash: "longdashdot"
        },
        {
            color: getColorValue("fuchsia"),
            symbol: "triangle-down",
            dash: "longdashdot"
        },
        {
            color: getColorValue("navy"),
            symbol: "diamond",
            dash: "longdash"
        },
        {
            color: getColorValue("purple"),
            symbol: "circle-open",
            dash: "dashdot"
        },
        {
            color: getColorValue("lime"),
            symbol: "triangle-up-open",
            dash: "solid"
        },
        {
            color: getColorValue("maroon"),
            symbol: "square-open",
            dash: "dash"
        },
        {
            color: getColorValue("aqua"),
            symbol: "triangle-down-open",
            dash: "longdashdot"
        },
        {
            color: getColorValue("olive"),
            symbol: "diamond-open",
            dash: "longdashdot"
        },
        {
            color: getColorValue("blue"),
            symbol: "circle-open",
            dash: "longdash"
        },
        {
            color: getColorValue("red"),
            symbol: "triangle-up-open",
            dash: "dashdot"
        },
        { color: getColorValue("green"), symbol: "square-open", dash: "solid" },
        {
            color: getColorValue("fuchsia"),
            symbol: "triangle-down-open",
            dash: "dash"
        },
        {
            color: getColorValue("navy"),
            symbol: "diamond-open",
            dash: "longdashdot"
        },
        {
            color: getColorValue("purple"),
            symbol: "circle-open",
            dash: "longdashdot"
        },
        {
            color: getColorValue("lime"),
            symbol: "triangle-up-open",
            dash: "longdash"
        },
        {
            color: getColorValue("maroon"),
            symbol: "square-open",
            dash: "dashdot"
        },
        {
            color: getColorValue("aqua"),
            symbol: "triangle-down-open",
            dash: "solid"
        },
        { color: getColorValue("olive"), symbol: "diamond-open", dash: "dash" },
        { color: getColorValue("black"), symbol: "circle-open", dash: "solid" }
    ];

    const getSeriesDataFromRawData = (
        RawData: AnalysisResponseType
    ): PlotData[] => {
        const series: PlotData[] = [];
        RawData.cyRheology?.samples.forEach((sample, index) => {
            const measurementFrequency: number[] =
                sample.measurements.angularFrequency;
            const measurementViscosity: number[] =
                sample.measurements.complexShearViscosity;
            const modelFrequency: number[] =
                sample.viscosityModel.angularFrequency;
            const modelViscosity: number[] =
                sample.viscosityModel.complexShearViscosity;
            const lastStyleAssignmentIndex: number = styleAssignment.length - 1;
            const styleAssignmentIndex: number =
                index < styleAssignment.length
                    ? index
                    : lastStyleAssignmentIndex;
            const { color, symbol, dash } =
                styleAssignment[styleAssignmentIndex];
            const width = 2;
            const shape = "spline";

            // Measurement
            series.push({
                x: measurementFrequency,
                y: measurementViscosity,
                type: "scatter",
                mode: "markers",
                name: `${sample.name}`,
                marker: { color, symbol }
            } as PlotData);
            // CY Fit
            series.push({
                x: modelFrequency,
                y: modelViscosity,
                type: "scatter",
                mode: "lines",
                name: `${sample.name} CY Model`,
                marker: { color },
                line: { color, width, shape, dash }
            } as PlotData);
        });
        return series;
    };

    const data = getSeriesDataFromRawData(RawData);

    spaceTrackEvent({
        type: LOADING_USER_REPORT,
        contents: JSON.stringify({ rawData: RawData, seriesData: data })
    });

    return <PreviewChart data={data} temp={Temp} testId={testId} />;
};

export default PreviewChartDataProvider;
