import React from "react";
import { Button, Card, Modal, ModalContent } from "@cpchem/covalence-ui";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StoredDataset } from "@services/space/datasets/models";
import { ScalarValuesSection } from "./scalar-values-section";
import { SeriesValuesSection } from "./series-values-section";
import "../styles.scss";
import { MetadataValuesSection } from "./metadata-values-section";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface DatasetsModalProps {
    className?: string;
    isOpen: boolean;
    dataset: StoredDataset;
    onRemoveDataset: () => void;
    onRequestClose: () => void;
    testId?: string;
}

export function DatasetsModal({
    className,
    isOpen,
    dataset,
    onRemoveDataset,
    onRequestClose,
    testId
}: DatasetsModalProps): JSX.Element {
    const cnParts = ["datasets-modal", "space-modal"];

    if (className) {
        cnParts.push(className);
    }

    function handleRemoveFile() {
        onRemoveDataset();
        onRequestClose();
    }

    const hasMetadata = dataset.metadata && dataset.metadata.length > 0;
    const hasScalars =
        dataset.scalars &&
        dataset.scalars.length > 0 &&
        dataset.scalars.some(
            (scalar) => scalar.items && scalar.items.length > 0
        );

    const metadataTestId = testId ? `${testId}-metadata` : undefined;
    const scalarsTestId = testId ? `${testId}-scalars` : undefined;
    const seriesTestId = testId ? `${testId}-series` : undefined;
    const removeButtonTestId = testId ? `${testId}-remove` : undefined;
    const closeButtonTestId = testId ? `${testId}-close` : undefined;

    const cn = cnParts.join(" ");
    return (
        <Modal
            className={cn}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            showCloseButton={false}
            testId={testId}
        >
            <div className="modal-header">
                <Button
                    className="remove-dataset"
                    color="danger"
                    icon={<FontAwesomeIcon icon={faTrash as IconProp} />}
                    variant="text"
                    onClick={handleRemoveFile}
                    size="large"
                    testId={removeButtonTestId}
                />
                <h1>{dataset.fileName}</h1>
                <Button
                    className="modal-close-button"
                    onClick={onRequestClose}
                    variant="text"
                    icon={<FontAwesomeIcon icon={faX as IconProp} />}
                    size="large"
                    testId={closeButtonTestId}
                />
            </div>
            <ModalContent>
                <Card>
                    {hasMetadata && (
                        <MetadataValuesSection
                            metadata={dataset.metadata}
                            testId={metadataTestId}
                        />
                    )}
                    {hasScalars && (
                        <ScalarValuesSection
                            scalars={dataset.scalars}
                            testId={scalarsTestId}
                        />
                    )}
                    <SeriesValuesSection
                        series={dataset.series}
                        testId={seriesTestId}
                    />
                </Card>
            </ModalContent>
        </Modal>
    );
}
