import { Tooltip, Checkbox } from "@cpchem/covalence-ui";
import React from "react";
import "./data-grid-header-styles.scss";

export interface HeaderRendererFullProps {
    allFilesSelected: boolean;
    onSelectAllFiles: () => void;
    isDatasetsPage?: boolean;
    testId?: string;
}

export function HeaderRendererFull({
    allFilesSelected,
    onSelectAllFiles,
    isDatasetsPage,
    testId
}: HeaderRendererFullProps): JSX.Element {
    const selectAllTestId = testId ? `${testId}-select-all` : undefined;
    const CheckboxHeader = (
        <Tooltip content="Select all datasets" side="topLeft" enterDelay="long">
            <Checkbox
                isChecked={allFilesSelected}
                label=""
                onChange={onSelectAllFiles}
                testId={selectAllTestId}
            />
        </Tooltip>
    );
    return (
        <thead className="is-sticky">
            <tr>
                <th className="checkbox-header">
                    <span className="header-cell justify-left checkbox">
                        {CheckboxHeader}
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left name">Name</span>
                </th>
                <th>
                    <span className="header-cell justify-left size">Size</span>
                </th>
                <th>
                    <span className="header-cell justify-left format">
                        Data Format
                    </span>
                </th>
                {isDatasetsPage && (
                    <th>
                        <span className="header-cell justify-right remove" />
                    </th>
                )}
            </tr>
        </thead>
    );
}
