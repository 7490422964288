// Datasets Page
export const LOADING_DATASETS = "LOADING_DATASETS";
export const GET_DATASET_FORMATS = "GET_DATASET_FORMATS";
export const GET_DATASET_CONVERT = "GET_DATASET_CONVERT";
export const NAVIGATE_TO_ANALYSIS_PAGE = "NAVIGATE_TO_ANALYSIS_PAGE";

// Login Page
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";

// Dataset management
export const OPEN_DATASET = "OPEN_DATASET";
export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_ALL_DATASETS = "DELETE_ALL_DATASETS";

// Report Analysis Page
export const LOADING_USER_PARAMETERS = "LOADING_USER_PARAMETERS";
export const LOADING_USER_REPORT = "LOADING_USER_REPORT";

// Analysis Parameters
export const UPDATE_ANALYSIS_PARAMETERS = "UPDATE_ANALYSIS_PARAMETERS";

// Rheology Analysis
export const GET_RHEOLOGY_REPORT = "GET_RHEOLOGY_REPORT";
export const GET_ANALYSIS_RESULTS = "GET_ANALYSIS_RESULTS";

// Download Events
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";

// Error Events
export const ERROR_GET_RHEOLOGY_REPORT = "ERROR_GET_RHEOLOGY_REPORT";
export const ERROR_GET_ANALYSIS_RESULTS = "ERROR_GET_ANALYSIS_RESULTS";
export const ERROR_DOWNLOAD_REPORT = "ERROR_DOWNLOAD_REPORT";
export const ERROR_GET_DATASET_CONVERT = "ERROR_GET_DATASET_CONVERT";
export const ERROR_GET_DATASET_FORMATS = "ERROR_GET_DATASET_FORMATS";
export const ERROR_LOADING_USER_DATASETS = "ERROR_LOADING_USER_DATASETS";
export const ERROR_INVALID_FILE_FORMAT_UPLOAD =
    "ERROR_INVALID_FILE_FORMAT_UPLOAD";
export const ERROR_DUPLICATE_FILE_UPLOAD = "ERROR_DUPLICATE_FILE_UPLOAD";
export const ERROR_BAD_DATA_FILE_UPLOAD = "ERROR_BAD_DATA_FILE_UPLOAD";

// Error page triggering
export const ERROR_PAGE_AUTHORIZATION = "ERROR_PAGE_AUTHORIZATION";
export const ERROR_UNAUTHORIZED_USER = "ERROR_UNAUTHORIZED_USER";
export const ERROR_UNAUTHENTICATED_USER = "ERROR_UNAUTHENTICATED_USER";
export const ERROR_PAGE_TECHNICAL_ISSUE = "ERROR_PAGE_TECHNICAL_ISSUE";
