import React, { useRef, DragEvent, KeyboardEvent } from "react";
import "./styles.scss";

interface DragAndDropUploadProps {
    onFilesAdded: (files: File[]) => void;
    isDragActive: boolean;
    testId?: string;
}

export function DragAndDropUpload({
    onFilesAdded,
    isDragActive,
    testId
}: DragAndDropUploadProps): JSX.Element {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const cnParts = ["drag-overlay"];

    if (isDragActive) {
        cnParts.push("visible");
    }

    function handleDrop(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        onFilesAdded(files);
    }

    function handleKeyboard(event: KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter" || event.key === " ") {
            fileInputRef.current?.click();
        }
    }

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files ? Array.from(event.target.files) : [];
        onFilesAdded(files);
    }

    const inputTestId = testId ? `${testId}-drag-input` : undefined;

    const cn = cnParts.join(" ");
    return (
        <div
            className={cn}
            onDrop={handleDrop}
            onKeyDown={handleKeyboard}
            role="button"
            tabIndex={0}
            aria-label="File Upload"
            data-testid={testId}
        >
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                data-testid={inputTestId}
            />
        </div>
    );
}
