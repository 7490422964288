import React, { useState, useEffect, KeyboardEvent } from "react";
import { StoredDataset } from "@services/space/datasets/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { debounce } from "lodash";

export interface DatasetsSearchBarProps {
    className?: string;
    datasets: StoredDataset[];
    isLoading?: boolean;
    onSelectDataset: (datasetName: string) => void;
    testId?: string;
}

export function DatasetsSearchBar({
    className,
    datasets,
    isLoading,
    onSelectDataset,
    testId
}: DatasetsSearchBarProps) {
    const [search, setSearch] = useState("");
    const [highlightIndex, setHighlightIndex] = useState(-1);
    const cnParts = ["datasets-search-bar"];

    const searchListTestId = testId ? `${testId}-list` : undefined;
    const searchBarInputTestId = testId ? `${testId}-input` : undefined;
    const magnifyingGlassTestId = testId
        ? `${testId}-magnifying-glass`
        : undefined;
    const searchListItemTestId = testId ? `${testId}-list-item` : undefined;

    if (className) {
        cnParts.push(className);
    }
    if (isLoading) {
        cnParts.push("loading");
    }

    const debounceSearch = debounce((searchValue: string) => {
        setSearch(searchValue);
    }, 300);

    useEffect(() => {
        debounceSearch(search);
        return () => debounceSearch.cancel();
    }, [search, debounceSearch]);

    const filteredDatasets = datasets
        .filter((dataset) =>
            dataset.fileName.toLowerCase().includes(search.toLowerCase())
        )
        .filter((_, index) => index < 5);

    const handleDatasetClick = (datasetName: string) => {
        onSelectDataset(datasetName);
        setSearch("");
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleBlur = () => {
        setHighlightIndex(-1);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case "Enter":
                if (filteredDatasets.length && search) {
                    handleDatasetClick(
                        filteredDatasets[highlightIndex].fileName
                    );
                    event.preventDefault();
                }
                break;
            case "Tab":
                if (filteredDatasets.length && highlightIndex >= 0) {
                    handleDatasetClick(
                        filteredDatasets[highlightIndex].fileName
                    );
                    event.preventDefault();
                }
                break;
            case "ArrowDown":
                setHighlightIndex(
                    (prevIndex) => (prevIndex + 1) % filteredDatasets.length
                );
                event.preventDefault();
                break;
            case "ArrowUp":
                setHighlightIndex(
                    (prevIndex) =>
                        (prevIndex + filteredDatasets.length - 1) %
                        filteredDatasets.length
                );
                event.preventDefault();
                break;
        }
    };

    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={testId}>
            <div className="input-icon-wrapper">
                <input
                    type="text"
                    className={isLoading ? "loading" : ""}
                    placeholder="Search for datasets"
                    value={search}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    data-testid={searchBarInputTestId}
                    disabled={isLoading}
                />
                <FontAwesomeIcon
                    icon={faSearch as IconProp}
                    className="fa-magnifying-glass"
                    data-testid={magnifyingGlassTestId}
                />
                {filteredDatasets.length > 0 && !isLoading && (
                    <ul
                        data-testid={searchListTestId}
                        className={search.length > 0 ? "show" : ""}
                    >
                        {filteredDatasets.map((dataset, index) => (
                            <li
                                key={dataset.fileName}
                                className={
                                    index === highlightIndex ? "highlight" : ""
                                }
                                onClick={() =>
                                    handleDatasetClick(dataset.fileName)
                                }
                                onKeyDown={(event) => {
                                    if (
                                        event.key === "Enter" ||
                                        event.key === " "
                                    ) {
                                        handleDatasetClick(dataset.fileName);
                                    }
                                }}
                                tabIndex={0}
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                role="button"
                                data-testid={`${searchListItemTestId}-${dataset.fileName}`}
                            >
                                {dataset.fileName}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}
