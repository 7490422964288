import { FormatFileSize, GetFileExtension } from "./fileUtils";
import {
    postConvertDatasetSuccess,
    postConvertDatasetFailure,
    requestConvertDataset
} from "../../stores/datasets/datasets-actions";
import { DatasetConvertService } from "../../services/space/datasets/interface";
import {
    DatasetConvertResponse,
    StoredDataset
} from "@services/space/datasets/models";
import { spaceTrackEvent } from "../../logging/space-track-event";
import {
    ERROR_GET_DATASET_CONVERT,
    GET_DATASET_CONVERT
} from "../../logging/log-events";
import { ApiErrorResponse, ApiResponse } from "@services";

export const ProcessFile = async (
    file: File,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: any,
    datasetConvertService: DatasetConvertService
): Promise<StoredDataset | null> => {
    dispatch(requestConvertDataset());
    try {
        const rawData = await file.arrayBuffer();
        const response =
            await datasetConvertService.postDatasetConvert(rawData);

        if (response.data) {
            const conversionDate = new Date().toISOString();
            const { data } = response as ApiResponse<DatasetConvertResponse>;
            const processedFile: StoredDataset = {
                fileName: file.name,
                fileType: GetFileExtension(data.sourceFormat as string),
                fileSize: FormatFileSize(file.size),
                conversionDate: conversionDate,
                sourceFormat: data.sourceFormat,
                metadata: data.dataset.metadata,
                scalars: data.dataset.scalars,
                series: data.dataset.series
            };

            spaceTrackEvent({
                type: GET_DATASET_CONVERT,
                contents: JSON.stringify(processedFile)
            });

            dispatch(postConvertDatasetSuccess(data));
            return processedFile;
        }

        dispatch(
            postConvertDatasetFailure(
                (response as ApiErrorResponse) ?? {
                    title: "An unknown error occurred"
                }
            )
        );
        return null;
    } catch (error) {
        const message =
            error instanceof Error
                ? error.message
                : "An unknown error occurred";
        spaceTrackEvent({
            type: ERROR_GET_DATASET_CONVERT,
            contents: message
        });
        dispatch(postConvertDatasetFailure({ title: message }));
        return null;
    }
};
