import { LogEvent } from "@cpchem/logging";
import {
    LOADING_DATASETS,
    GET_DATASET_FORMATS,
    GET_DATASET_CONVERT,
    OPEN_DATASET,
    DELETE_DATASET,
    DELETE_ALL_DATASETS,
    LOADING_USER_PARAMETERS,
    LOADING_USER_REPORT,
    UPDATE_ANALYSIS_PARAMETERS,
    GET_RHEOLOGY_REPORT,
    GET_ANALYSIS_RESULTS,
    DOWNLOAD_REPORT,
    ERROR_GET_RHEOLOGY_REPORT,
    ERROR_GET_ANALYSIS_RESULTS,
    ERROR_DOWNLOAD_REPORT,
    ERROR_GET_DATASET_CONVERT,
    ERROR_PAGE_AUTHORIZATION,
    ERROR_PAGE_TECHNICAL_ISSUE,
    NAVIGATE_TO_ANALYSIS_PAGE,
    ERROR_GET_DATASET_FORMATS,
    ERROR_LOADING_USER_DATASETS,
    ERROR_INVALID_FILE_FORMAT_UPLOAD,
    ERROR_DUPLICATE_FILE_UPLOAD,
    ERROR_BAD_DATA_FILE_UPLOAD,
    ERROR_UNAUTHENTICATED_USER,
    ERROR_UNAUTHORIZED_USER,
    USER_AUTHENTICATED
} from "./log-events";

type sinkDefinition = (event: LogEvent) => Record<string, unknown>;

const standardEventSink: sinkDefinition = (event: LogEvent) => {
    return { prop: { ...parseEventContent(event.contents) } };
};

interface sinkDrawer {
    [key: string]: sinkDefinition;
}

function parseEventContent(contents: string) {
    try {
        const parsedObject = JSON.parse(contents);
        return parsedObject;
    } catch (e) {
        return {};
    }
}

export const spaceEventSinks: sinkDrawer = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [LOADING_DATASETS]: standardEventSink,
    [GET_DATASET_FORMATS]: standardEventSink,
    [GET_DATASET_CONVERT]: standardEventSink,
    [OPEN_DATASET]: standardEventSink,
    [DELETE_DATASET]: standardEventSink,
    [DELETE_ALL_DATASETS]: standardEventSink,
    [LOADING_USER_PARAMETERS]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                userLocalParameters: parsed.userLocalParameters,
                currentUser: parsed.currentUserId
            }
        };
    },
    [LOADING_USER_REPORT]: standardEventSink,
    [NAVIGATE_TO_ANALYSIS_PAGE]: standardEventSink,
    [USER_AUTHENTICATED]: standardEventSink,

    [UPDATE_ANALYSIS_PARAMETERS]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                currentUser: parsed.currentUserId,
                updatedParameters: parsed.updates
            }
        };
    },
    [GET_RHEOLOGY_REPORT]: standardEventSink,
    [GET_ANALYSIS_RESULTS]: standardEventSink,
    [DOWNLOAD_REPORT]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };

        return {
            prop: {
                currentUser: parsed.currentUserId,
                downloadedFileName: parsed.downloadedFileName
            }
        };
    },
    [ERROR_GET_RHEOLOGY_REPORT]: standardEventSink,
    [ERROR_GET_ANALYSIS_RESULTS]: standardEventSink,
    [ERROR_DOWNLOAD_REPORT]: standardEventSink,
    [ERROR_GET_DATASET_CONVERT]: standardEventSink,
    [ERROR_GET_DATASET_FORMATS]: standardEventSink,
    [ERROR_LOADING_USER_DATASETS]: standardEventSink,
    [ERROR_INVALID_FILE_FORMAT_UPLOAD]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                currentUser: parsed.currentUserId,
                invalidFiles: parsed.invalidFilesNames
            }
        };
    },
    [ERROR_DUPLICATE_FILE_UPLOAD]: standardEventSink,
    [ERROR_BAD_DATA_FILE_UPLOAD]: (event: LogEvent) => {
        const parsed = { ...parseEventContent(event.contents) };
        return {
            prop: {
                currentUser: parsed.currentUserId,
                invalidFiles: parsed.failedFilesNames
            }
        };
    },
    [ERROR_PAGE_AUTHORIZATION]: standardEventSink,
    [ERROR_UNAUTHENTICATED_USER]: standardEventSink,
    [ERROR_UNAUTHORIZED_USER]: standardEventSink,
    [ERROR_PAGE_TECHNICAL_ISSUE]: standardEventSink
};
