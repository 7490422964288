import React from "react";
import {
    datasetSeries,
    datasetSeriesMetrics
} from "@services/space/datasets/models";

interface SeriesValuesSectionProps {
    series: datasetSeries[];
    testId?: string;
}

export function SeriesValuesSection({
    series,
    testId
}: SeriesValuesSectionProps): JSX.Element {
    const tableHeadersTestId = testId ? `${testId}-table-header` : undefined;
    const tableRowsTestId = testId ? `${testId}-table-row` : undefined;

    const renderTableHeaders = (columnDefinitions: datasetSeriesMetrics[]) => {
        return columnDefinitions.map((column) => (
            <th
                className="series-values-header-row-title"
                key={`header-${column.title}`}
            >
                {column.title}
                {column.unit && column.unit.abbreviation
                    ? ` (${column.unit.abbreviation})`
                    : ""}
            </th>
        ));
    };

    const renderTableRows = (
        rows: number[][] | string[][],
        columnDefinitions: datasetSeriesMetrics[]
    ) => {
        return rows.map((row, rowIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr className="series-values-datasets-row" key={`row-${rowIndex}`}>
                {row.map((cell, cellIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={`cell-${cellIndex}`}>
                        {cell !== null && cell !== undefined ? cell : "..."}
                    </td>
                ))}
                {row.length < columnDefinitions.length &&
                    [...Array(columnDefinitions.length - row.length)].map(
                        (_, placeholderIndex) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`placeholder-${placeholderIndex}`}>...</td>
                        )
                    )}
            </tr>
        ));
    };

    return (
        <div className="series-values-section" data-testid={testId}>
            {series.map((serie) => (
                <div
                    key={`table-${serie.title}`}
                    className="series-values-table-wrapper"
                >
                    <h1>Series Values</h1>
                    <h2>{serie.title}</h2>
                    <div className="scrollable-table-container-series">
                        <table className="series-values-table">
                            <thead data-testid={tableHeadersTestId}>
                                <tr className="series-values-header-row">
                                    {renderTableHeaders(serie.metrics)}
                                </tr>
                            </thead>
                            <tbody data-testid={tableRowsTestId}>
                                {renderTableRows(
                                    serie.dataPoints,
                                    serie.metrics
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
}
