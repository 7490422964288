import React, { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox, Divider, Select, TextField } from "@cpchem/covalence-ui";
import {
    CyDataTypes,
    CyRheologyParameters,
    CyResinTypes
} from "../../../interfaces/analysis-types";
import {
    ValidateDataType,
    ValidateNValue,
    ValidateVogelTemp,
    ValidateReferenceTemp,
    ValidateDatasets
} from "./cy-rheology-validations";
import { getTestIds } from "./cy-test-ids";
import isEqual from "lodash/isEqual";
import { localStorageService } from "../../../utilities/dataset-page/localStorageService";
import { useAuth } from "../../../hooks/use-auth";
import { StoredDataset } from "@services/space/datasets/models";
import { DatasetsSelector } from "../datasets-selector/datasets-selector";
import "./styles.scss";
import { SpaceAccordion } from "../../index";

interface CyRheologyFormProps {
    className?: string;
    isOnReportPage?: boolean;
    isLoading?: boolean;
    initialParameters: CyRheologyParameters;
    onUpdate: (updates: Partial<CyRheologyParameters>) => void;
    onValidation: (isValid: boolean) => void;
    testId?: string;
}

export function CyRheologyForm({
    isOnReportPage = false,
    isLoading,
    initialParameters,
    onUpdate,
    onValidation,
    testId
}: CyRheologyFormProps): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState<string | null>(
        "Datasets"
    );
    const localState = useRef<CyRheologyParameters>(initialParameters);
    const { currentAccount } = useAuth();
    const currentUserId = currentAccount?.username;
    const userUploadedDatasets = localStorageService.loadDatasetsForUser(
        currentUserId as string
    );

    const {
        resinTypeTestId,
        compareDatasetsTestId,
        compareDatasetsCheckboxTestId,
        dataTypesTestId,
        dataTypesFreqSweepTestId,
        dataTypesTempFreqSweepTestId,
        fixNTestId,
        fixNYesTestId,
        fixNNoTestId,
        fixNTextFieldTestId,
        vogelTempTestId,
        vogelTempTextFieldTestId,
        referenceTempTestId,
        referenceTempTextFieldTestId,
        yieldStressTestId,
        yieldStressCheckboxTestId
    } = getTestIds(testId);

    useEffect(() => {
        onUpdate(localState.current);
    }, [onUpdate]);

    useEffect(() => {
        const isAllValid =
            ValidateDatasets(localState.current.selectedDatasets.length) &&
            ValidateDataType(localState.current.dataType) &&
            ValidateNValue(
                localState.current.fixN,
                localState.current.nValue
            ) &&
            ValidateVogelTemp(localState.current.vogelTemp) &&
            ValidateReferenceTemp(localState.current.referenceTemp) &&
            ValidateDatasets(localState.current.selectedDatasets.length);
        onValidation(isAllValid);
    }, [
        onValidation,
        localState.current.selectedDatasets.length,
        localState.current.dataType,
        localState.current.fixN,
        localState.current.nValue,
        localState.current.vogelTemp,
        localState.current.referenceTemp
    ]);

    const handleStateUpdate = useCallback(
        (newPartialState: Partial<CyRheologyParameters>) => {
            const updatedState = { ...localState.current, ...newPartialState };
            if (!isEqual(localState.current, updatedState)) {
                localState.current = updatedState;
                onUpdate(updatedState);
            }
        },
        [onUpdate]
    );

    const handleFieldUpdate = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (field: keyof CyRheologyParameters, value: any) => {
            const newParams = { ...localState.current, [field]: value };
            localState.current = newParams;
            onUpdate(newParams);
        },
        [onUpdate]
    );

    const handleChangeDataType = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        handleFieldUpdate("dataType", event.target.value as CyDataTypes);
    };

    const handleChangeFixN = (fixNValue: boolean) => {
        handleFieldUpdate("fixN", fixNValue);
    };

    const handleToggleYieldStressTerm = (checked: boolean) => {
        handleFieldUpdate("isUsingYieldStressTerm", checked);
    };

    const handleNumericChange = (
        key: keyof CyRheologyParameters,
        value: string
    ) => {
        if (/^\d*\.?\d*$/.test(value) || value === "") {
            handleFieldUpdate(key, value);
        }
    };

    const handleResinTypeChange = useCallback(
        (value: string) => {
            handleStateUpdate({ resinType: value as CyResinTypes });
        },
        [handleStateUpdate]
    );

    const handleSelectComparisonDataset = useCallback(
        (value: string) => {
            handleStateUpdate({ selectedCompareDataset: value });
        },
        [handleStateUpdate]
    );

    const toggleComparingDatasets = useCallback(() => {
        handleStateUpdate({
            isComparingDatasets: !localState.current.isComparingDatasets
        });
    }, [handleStateUpdate]);

    const handleDatasetSelection = useCallback(
        (selectedDataset: StoredDataset) => {
            const updatedDatasets = [
                ...localState.current.selectedDatasets,
                selectedDataset
            ];
            handleStateUpdate({ selectedDatasets: updatedDatasets });
        },
        [handleStateUpdate]
    );

    const handleDatasetDismissal = useCallback(
        (datasetName: string) => {
            const filteredDatasets = localState.current.selectedDatasets.filter(
                (d) => d.fileName !== datasetName
            );
            handleStateUpdate({ selectedDatasets: filteredDatasets });
        },
        [handleStateUpdate]
    );

    const handleDatasetReorder = useCallback(
        (updatedDatasets: StoredDataset[]) => {
            handleStateUpdate({ selectedDatasets: updatedDatasets });
        },
        [handleStateUpdate]
    );

    const resinTypes = [
        { key: "PE", value: "PE", text: "PE" },
        { key: "PP", value: "PP", text: "PP" },
        { key: "PMP", value: "PMP", text: "PMP" },
        { key: "PS", value: "PS", text: "PS" },
        { key: "Other", value: "Other", text: "Other" }
    ];

    const DatasetsAccordionTestId = testId
        ? `${testId}-datasets-accordion`
        : undefined;
    const ReportParametersAccordionTestId = testId
        ? `${testId}-report-parameters-accordion`
        : undefined;

    return (
        <div className="parameters-content">
            <SpaceAccordion
                label="Datasets"
                isOpen={openAccordion === "Datasets"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Datasets" ? null : "Datasets"
                    )
                }
                defaultOpen
                testId={DatasetsAccordionTestId}
            >
                <DatasetsSelector
                    selectedDatasets={localState.current.selectedDatasets}
                    onSelectDataset={(datasetName: string) => {
                        const dataset = userUploadedDatasets?.find(
                            (d) => d.fileName === datasetName
                        );
                        if (dataset) handleDatasetSelection(dataset);
                    }}
                    onHandleDismissDataset={handleDatasetDismissal}
                    onHandleDatasetReorder={handleDatasetReorder}
                    hasTitle
                    isLoading={isLoading}
                />
            </SpaceAccordion>
            <Divider />
            <SpaceAccordion
                label="Report Parameters"
                isOpen={openAccordion === "Report Parameters"}
                onToggle={() =>
                    setOpenAccordion(
                        openAccordion === "Report Parameters"
                            ? null
                            : "Report Parameters"
                    )
                }
                testId={ReportParametersAccordionTestId}
            >
                <div className="parameter-section two-items resin-type">
                    <h4>Resin Type</h4>
                    <Select
                        className="resin-type-select"
                        label="Resin Type"
                        isLabelHidden
                        options={resinTypes}
                        value={localState.current.resinType}
                        onChange={handleResinTypeChange}
                        testId={resinTypeTestId}
                        isDisabled={isLoading}
                    />
                </div>
                {!isOnReportPage && (
                    <div className="parameter-section three-items compare-datasets">
                        <h4>Compare Dataset</h4>
                        <div className="compare-dataset-checkbox">
                            <Checkbox
                                label=""
                                isChecked={
                                    localState.current.isComparingDatasets
                                }
                                onChange={toggleComparingDatasets}
                                testId={compareDatasetsCheckboxTestId}
                                isDisabled={isLoading}
                            />
                        </div>

                        <Select
                            className="compare-dataset-select"
                            label="Compare Datasets"
                            isLabelHidden
                            options={localState.current.selectedDatasets.map(
                                (d) => ({
                                    key: d.fileName,
                                    value: d.fileName,
                                    text: d.fileName
                                })
                            )}
                            value={
                                localState.current.selectedCompareDataset || ""
                            }
                            onChange={handleSelectComparisonDataset}
                            isDisabled={
                                !localState.current.isComparingDatasets ||
                                isLoading
                            }
                            testId={compareDatasetsTestId}
                        />
                    </div>
                )}
                <div
                    className="parameter-section three-items data-type"
                    data-testid={dataTypesTestId}
                >
                    <h4>Data Type</h4>
                    <div className="parameter-radio-buttons data-type-radio-buttons">
                        <label className="data-type-freq-sweep">
                            <input
                                type="radio"
                                name="data-type"
                                value="frequencySweep"
                                checked={
                                    localState.current.dataType ===
                                    "frequencySweep"
                                }
                                onChange={handleChangeDataType}
                                data-testid={dataTypesFreqSweepTestId}
                                disabled={isLoading}
                            />
                            Frequency Sweep
                        </label>
                        <label className="data-type-temp-freq-sweep">
                            <input
                                type="radio"
                                name="data-type"
                                value="tempFreqSweep"
                                checked={
                                    localState.current.dataType ===
                                    "tempFreqSweep"
                                }
                                onChange={handleChangeDataType}
                                data-testid={dataTypesTempFreqSweepTestId}
                                disabled={isLoading}
                            />
                            Temp/Freq Sweep
                        </label>
                    </div>
                </div>

                <div
                    className="parameter-section three-items fix-n-at"
                    data-testid={fixNTestId}
                >
                    <h4>Fix n at</h4>
                    <div className="parameter-radio-buttons fix-n-at-radio-buttons">
                        <label className="fix-n-yes">
                            <input
                                type="radio"
                                name="fix-n"
                                checked={localState.current.fixN === true}
                                onChange={() => handleChangeFixN(true)}
                                data-testid={fixNYesTestId}
                                disabled={isLoading}
                            />
                            Yes
                        </label>
                        <label className="fix-n-no">
                            <input
                                type="radio"
                                name="fix-n"
                                checked={localState.current.fixN === false}
                                onChange={() => handleChangeFixN(false)}
                                data-testid={fixNNoTestId}
                                disabled={isLoading}
                            />
                            No
                        </label>
                    </div>
                    <div className="fix-n-text-field parameters-text-field">
                        <TextField
                            label="Fixed n Value"
                            isLabelHidden
                            value={localState.current.nValue || ""}
                            onChange={(value) =>
                                handleNumericChange("nValue", value)
                            }
                            isDisabled={!localState.current.fixN || isLoading}
                            testId={fixNTextFieldTestId}
                        />
                    </div>
                </div>

                <div
                    className="parameter-section two-items vogel-temp"
                    data-testid={vogelTempTestId}
                >
                    <h4>Vogel Temperature (K)</h4>
                    <div className="vogel-temp-text-field parameters-text-field">
                        <TextField
                            label="Vogel Temperature"
                            isLabelHidden
                            value={localState.current.vogelTemp || ""}
                            onChange={(value) =>
                                handleNumericChange("vogelTemp", value)
                            }
                            isDisabled={isLoading}
                            testId={vogelTempTextFieldTestId}
                        />
                    </div>
                </div>

                <div
                    className="parameter-section two-items reference-temp"
                    data-testid={referenceTempTestId}
                >
                    <h4>Reference Temperature (°C)</h4>
                    <div className="reference-temp-text-field parameters-text-field">
                        <TextField
                            label="Reference Temperature"
                            isLabelHidden
                            value={localState.current.referenceTemp || ""}
                            onChange={(value) =>
                                handleNumericChange("referenceTemp", value)
                            }
                            isDisabled={isLoading}
                            testId={referenceTempTextFieldTestId}
                        />
                    </div>
                </div>

                <div
                    className="parameter-section two-items yield-stress"
                    data-testid={yieldStressTestId}
                >
                    <h4>Yield Stress Term</h4>
                    <div className="yield-stress-checkbox">
                        <Checkbox
                            label=""
                            isChecked={
                                localState.current.isUsingYieldStressTerm
                            }
                            onChange={(checked: boolean) =>
                                handleToggleYieldStressTerm(checked)
                            }
                            isDisabled={isLoading}
                            testId={yieldStressCheckboxTestId}
                        />
                    </div>
                </div>
                {isOnReportPage && (
                    <div className="parameter-section three-items compare-datasets">
                        <h4>Compare Dataset</h4>
                        <div className="compare-dataset-checkbox">
                            <Checkbox
                                label=""
                                isChecked={
                                    localState.current.isComparingDatasets
                                }
                                onChange={toggleComparingDatasets}
                                isDisabled={isLoading}
                                testId={compareDatasetsCheckboxTestId}
                            />
                        </div>

                        <Select
                            className="compare-dataset-select"
                            label="Compare Datasets"
                            isLabelHidden
                            options={localState.current.selectedDatasets.map(
                                (d) => ({
                                    key: d.fileName,
                                    value: d.fileName,
                                    text: d.fileName
                                })
                            )}
                            value={
                                localState.current.selectedCompareDataset || ""
                            }
                            onChange={handleSelectComparisonDataset}
                            isDisabled={
                                !localState.current.isComparingDatasets ||
                                isLoading
                            }
                            testId={compareDatasetsTestId}
                        />
                    </div>
                )}
            </SpaceAccordion>
        </div>
    );
}
