import { formatNameToExtension } from "../../stores/datasets/datasets-actions";

export const FormatFileSize = (size: number): string => {
    if (size < 1024) {
        return `${size} bytes`;
    }
    if (size < 1048576) {
        return `${Math.round(size / 1024)} KB`;
    }
    return `${(size / 1048576).toFixed(2)} MB`;
};

export const GetFileExtension = (sourceFormat: string): string =>
    formatNameToExtension[sourceFormat.toLowerCase()] || "Unknown";
