import { ApiErrorResponse, ApiResponse } from "../api-response";
import { PersonalizationRecord } from "./personalization-record";

export interface PersonalizationService {
    /** Deletes a single Personalization entry based on the combination of Application ID, User Principal Name, and Key */
    deletePersonalizationByKey(
        key: string
    ): Promise<ApiResponse<null> | ApiErrorResponse>;

    /** Get all personalization results for the combination of Application ID and User Principal Name */
    getAllPersonalization(): Promise<
        ApiResponse<PersonalizationRecord[] | null> | ApiErrorResponse
    >;

    /** Gets a single Personalization entry based on the combination of Application ID, User Principal Name, and Key */
    getPersonalizationByKey(
        key: string
    ): Promise<ApiResponse<PersonalizationRecord | null> | ApiErrorResponse>;

    /** Saves a personalization entry based on the combination of Application ID, User Principal Name, and Key */
    savePersonalizationByKey(
        key: string,
        values: string
    ): Promise<ApiResponse<null> | ApiErrorResponse>;
}

export const ServiceKey = "PersonalizationService";
