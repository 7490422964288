import { ApiFileResponse } from "@services/api-response";

export function downloadFile(
    response: ApiFileResponse,
    defaultFilename: string,
    linkFactory?: (doc: Document) => HTMLAnchorElement
) {
    const blobUrl = URL.createObjectURL(response.content);
    let link: HTMLAnchorElement | undefined = undefined;
    try {
        link =
            linkFactory !== undefined
                ? linkFactory(document)
                : document.createElement("a");
        link.href = blobUrl;
        link.download = response.filename ?? defaultFilename;
        document.body.appendChild(link);
        link.click();
    } finally {
        if (link !== undefined) {
            document.body.removeChild(link);
        }
        URL.revokeObjectURL(blobUrl);
    }
}
