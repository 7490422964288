import React from "react";
import { datasetScalars } from "@services/space/datasets/models";

interface ScalarValuesSectionProps {
    scalars: datasetScalars[];
    testId?: string;
}

export function ScalarValuesSection({
    scalars,
    testId
}: ScalarValuesSectionProps): JSX.Element {
    const scalarRows = scalars.flatMap((scalar) =>
        scalar.items.map((item) => (
            <tr className="scalar-values-row" key={`scalar-${item.title}`}>
                <th className="scalar-values-row-title">
                    {item.title}
                    {item.unit && item.unit.abbreviation
                        ? ` (${item.unit.abbreviation})`
                        : ""}
                </th>
                <td className="scalar-values-row-value">{item.value}</td>
            </tr>
        ))
    );

    return (
        <div className="scalar-values-section" data-testid={testId}>
            <h1>Scalar Values</h1>
            <div className="scrollable-table-container-scalars">
                <table className="scalar-values-table">
                    <tbody>{scalarRows}</tbody>
                </table>
            </div>
        </div>
    );
}
