import { CyRheologyForm } from "../../../components/report-parameters";
import {
    getDatasetFormats,
    postConvertDatasetFailure,
    setDataFormats,
    useDatasetManagement
} from "../../../stores/datasets";
import {
    AnalysisParameters,
    AnalysisType,
    CyRheologyParameters,
    defaultAnalysisParameters
} from "../../../interfaces/analysis-types";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { Button, Card, Tooltip } from "@cpchem/covalence-ui";
import { useAnalysisParametersHandlers } from "../../../utilities/report-analysis/useAnalysisParametersHandlers";
import { analysisTypeToTitle } from "../../../utilities/report-analysis/getAnalysisTitle";
import { LoadingDisplay } from "../../../components";
import { useNavigate } from "react-router-dom";
import { localStorageService } from "../../../utilities/dataset-page/localStorageService";
import { useAuth } from "../../../hooks/use-auth";
import PreviewChartDataProvider from "./preview-chart-data-provider";
import { useService } from "../../../service-provider";
import {
    ApiErrorResponse,
    ApiResponse,
    DatasetFormatsService,
    SpaceServiceKey
} from "@services";
import { spaceTrackEvent } from "../../../logging/space-track-event";
import {
    ERROR_GET_DATASET_FORMATS,
    GET_DATASET_FORMATS,
    LOADING_USER_PARAMETERS,
    UPDATE_ANALYSIS_PARAMETERS
} from "../../../logging/log-events";
import { DatasetFormatsResponse } from "@services/space/datasets/models";

export interface AnalysisReportViewProps {
    className?: string;
    testId?: string;
}

export function AnalysisReportView({
    className,
    testId
}: AnalysisReportViewProps): JSX.Element {
    const cnParts = ["analysis-preview"];
    const navigate = useNavigate();
    const { currentAccount } = useAuth();
    const currentUserId = currentAccount?.username;
    const userLocalParameters =
        localStorageService.loadAnalysisParametersForUser(
            currentUserId as string
        );
    if (userLocalParameters) {
        spaceTrackEvent({
            type: LOADING_USER_PARAMETERS,
            contents: JSON.stringify({ userLocalParameters, currentUserId })
        });
    }

    const { state, dispatch } = useDatasetManagement();
    state.analysisParameters = userLocalParameters;
    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
    const defaultParams: AnalysisParameters =
        state.analysisParameters || defaultAnalysisParameters;
    const [analysisParams, setAnalysisParams] =
        useState<AnalysisParameters>(defaultParams);
    const [areParametersValid, setAreParametersValid] =
        useState<boolean>(false);
    const analysisReportPageTitle = `${analysisTypeToTitle[analysisParams.analysisType] || ""} Analysis Report`;

    const datasetFormatsService =
        // eslint-disable-next-line no-undef
        useService<DatasetFormatsService>(SpaceServiceKey);

    async function FetchDatasetFormats() {
        try {
            if (getDatasetFormats(state).length === 0) {
                const response =
                    await datasetFormatsService.getAllDatasetFormats();
                if (response?.data) {
                    dispatch(
                        setDataFormats(
                            (response as ApiResponse<DatasetFormatsResponse>)
                                .data.datasetFormats
                        )
                    );
                    spaceTrackEvent({
                        type: GET_DATASET_FORMATS,
                        contents: JSON.stringify({
                            datasetFormats: (
                                response as ApiResponse<DatasetFormatsResponse>
                            ).data.datasetFormats
                        })
                    });
                }
            }
        } catch (error) {
            const errorMessage =
                error instanceof Error
                    ? error.message
                    : "Failed to fetch dataset formats";
            dispatch(postConvertDatasetFailure({ title: errorMessage }));
            spaceTrackEvent({
                type: ERROR_GET_DATASET_FORMATS,
                contents: errorMessage
            });
        }
    }
    useEffect(() => {
        if (!state.loading) {
            setIsInitialLoading(false);
        }
        FetchDatasetFormats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    function onUpdateReport(analysisParameters: AnalysisParameters) {
        handleParametersUpdate(analysisParameters);
    }

    function onDownloadReport() {
        handleDownloadReport("Excel");
    }

    function onRequestCancel() {
        state.analysisResponse = null;
        navigate("/");
    }
    function onUpdateParameters() {
        handleSubmit();
        localStorageService.saveAnalysisParametersForUser(
            currentUserId as string,
            analysisParams
        );
        spaceTrackEvent({
            type: UPDATE_ANALYSIS_PARAMETERS,
            contents: JSON.stringify({ analysisParams, currentUserId })
        });
    }

    const { handleParametersUpdate, handleSubmit, handleDownloadReport } =
        useAnalysisParametersHandlers(
            analysisParams,
            setAnalysisParams,
            onUpdateReport,
            areParametersValid
        );

    const cyRheologyFormTestId = testId
        ? `${testId}-cy-rheology-form`
        : undefined;
    const cancelButtonTestId = testId ? `${testId}-cancel-button` : undefined;
    const updateReportButtonTestId = testId
        ? `${testId}-update-report-button`
        : undefined;
    const downloadReportButtonTestId = testId
        ? `${testId}-download-report-button`
        : undefined;

    const formComponents = {
        cyRheology: (
            <CyRheologyForm
                initialParameters={
                    analysisParams.cyRheologyParams as CyRheologyParameters
                }
                onUpdate={handleParametersUpdate}
                onValidation={setAreParametersValid}
                testId={cyRheologyFormTestId}
                isOnReportPage
                isLoading={state.loading}
            />
        ),
        other: <div>Placeholder for other analysis type form</div>
    };

    const renderForm = () =>
        formComponents[
            state.analysisParameters?.analysisType as AnalysisType
        ] || <div>Unsupported analysis type</div>;

    if (className) {
        cnParts.push(className);
    }

    const cn = cnParts.join(" ");
    return (
        <>
            {isInitialLoading ? (
                <LoadingDisplay showLoading className="initial-loading" />
            ) : (
                <div className={cn}>
                    <h1>{analysisReportPageTitle}</h1>
                    <div
                        data-testid="report-analysis-wrapper"
                        className="analysis-wrapper"
                    >
                        <Card className="analysis-card">
                            <div className="analysis-side-menu">
                                <div className="analysis-side-menu-content">
                                    {renderForm()}
                                </div>
                                <div className="analysis-side-menu-footer">
                                    <Button
                                        text={"Back"}
                                        onClick={onRequestCancel}
                                        color="primary"
                                        variant="outline"
                                        size="large"
                                        className="analysis-cancel-button"
                                        isDisabled={state.loading}
                                        testId={cancelButtonTestId}
                                    />
                                    {areParametersValid ? (
                                        <Button
                                            text="Update Report"
                                            onClick={onUpdateParameters}
                                            color="primary"
                                            variant="solid"
                                            size="large"
                                            className="analysis-update-report-button"
                                            testId={updateReportButtonTestId}
                                        />
                                    ) : (
                                        <Tooltip
                                            content="Please fill out all required fields"
                                            enterDelay="medium"
                                        >
                                            <Button
                                                text="Update Report"
                                                onClick={onUpdateParameters}
                                                isDisabled
                                                color="primary"
                                                variant="outline"
                                                size="large"
                                                className="analysis-update-report-button"
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            {state.loading ? (
                                <LoadingDisplay showLoading />
                            ) : (
                                <div className="analysis-preview-display">
                                    {state.error ? (
                                        <div className="error-message-container">
                                            <Card className="error-message-card">
                                                <div className="error-message">
                                                    <div className="top-message">
                                                        Error
                                                    </div>
                                                    <div className="bottom-message">
                                                        {state.error?.title ? (
                                                            <p>
                                                                {
                                                                    state.error
                                                                        .title
                                                                }
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {state.error?.detail ? (
                                                            <p>
                                                                {
                                                                    state.error
                                                                        .detail
                                                                }
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {state.error?.errors ? (
                                                            <ul>
                                                                {(
                                                                    (
                                                                        state.error as ApiErrorResponse
                                                                    )
                                                                        .errors as []
                                                                ).map(
                                                                    (
                                                                        e: string,
                                                                        i: number
                                                                    ) => (
                                                                        <li
                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                            key={`error-${i}`}
                                                                        >
                                                                            {e}
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    ) : state.analysisResponse ? (
                                        <div className="preview-container">
                                            <PreviewChartDataProvider
                                                RawData={state.analysisResponse}
                                                Temp={
                                                    state.analysisResponse.cyRheology?.summary.temperatures.join(
                                                        ", "
                                                    ) ?? ""
                                                }
                                                testId={testId}
                                            />
                                            <div className="buttons-overlay">
                                                <div className="buttons download-button">
                                                    <Button
                                                        icon={
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faDownload as IconProp
                                                                }
                                                            />
                                                        }
                                                        color="primary"
                                                        onClick={
                                                            onDownloadReport
                                                        }
                                                        testId={
                                                            downloadReportButtonTestId
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="no-preview-container">
                                            <Card className="no-preview">
                                                <div className="no-preview-message">
                                                    <div className="top-message">
                                                        No Report Generated
                                                    </div>
                                                    <div className="bottom-message">
                                                        Use the left panel to
                                                        configure your
                                                        parameters
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
}
