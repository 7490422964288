import React, { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { AlertBanner, Grid } from "@cpchem/covalence-ui";
import { AnalysisReportPage, DataSetsPage } from "../pages";
import { routePath } from "./route-paths";
import { SpaceAppBar } from "../components/space-app-bar";
import { useAuth } from "../hooks/use-auth";
import { setUserInLoggingContext } from "../logging/configure-logging";
import { spaceTrackEvent } from "../logging/space-track-event";
import { createPageViewEvent } from "@cpchem/logging";
import { USER_AUTHENTICATED } from "../logging/log-events";

export function App(): JSX.Element {
    const { authState, currentAccount } = useAuth();
    const location = useLocation();

    // track page view whenever the current location changes
    useEffect(() => {
        const path = location.pathname + location.search + location.hash;
        const pageView = createPageViewEvent(path, {
            ...location
        });
        spaceTrackEvent(pageView);
    }, [location]);

    useEffect(() => {
        if (authState === "Authenticated") {
            setUserInLoggingContext(currentAccount?.username ?? "anonymous");
            spaceTrackEvent({
                type: USER_AUTHENTICATED,
                contents: currentAccount?.username ?? "anonymous"
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, currentAccount]);

    return (
        <Grid className="app">
            <SpaceAppBar />
            <AlertBanner />
            <div className="main">
                <Routes>
                    <Route
                        path={routePath.DATASETS}
                        element={<DataSetsPage />}
                    />
                    <Route
                        path={routePath.REPORTPREVIEW}
                        element={<AnalysisReportPage />}
                    />
                </Routes>
            </div>
        </Grid>
    );
}

export default App;
