import React from "react";
import "./empty-grid-styles.scss";

export function EmptySelectorGrid(): JSX.Element {
    const headerText = "No Available Datasets";
    return (
        <div className="empty-grid">
            <h1 className="empty-heading">{headerText}</h1>
        </div>
    );
}
