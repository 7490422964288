import React, { useState } from "react";
import {
    Button,
    Card,
    DataGrid,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader
} from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { DatasetsSearchBar } from "./datasets-search-bar";
import { StoredDataset } from "@services/space/datasets/models";
import { useUserDatasets } from "../../../../hooks/dataset-hooks";
import {
    ColumnsRenderer,
    HeaderRendererFull
} from "../../../../pages/datasets-page/components/data-grid";
import { RowRendererFull } from "../../../../pages/datasets-page/components/data-grid/row-renderer";
import "./modal-styles.scss";
import { EmptySelectorGrid } from "./empty-selector-grid";

export interface DatasetsSelectorModalProps {
    className?: string;
    isOpen: boolean;
    isLoading?: boolean;
    selectedDatasets: StoredDataset[];
    onClose: () => void;
    onSelectDataset: (datasetName: string) => void;
    testId?: string;
}

export function DatasetsSelectorModal({
    className,
    isOpen,
    isLoading,
    selectedDatasets,
    onClose,
    onSelectDataset,
    testId
}: DatasetsSelectorModalProps): JSX.Element {
    const cnParts = ["datasets-selector-modal"];
    const gridWrapperCnParts = ["datasets-grid-wrapper"];
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const userUploadedDatasets = useUserDatasets();

    const filteredDatasets = userUploadedDatasets.filter(
        (dataset: StoredDataset) =>
            !selectedDatasets.some(
                (selected) => selected.fileName === dataset.fileName
            )
    );

    const dataGridEmpty =
        filteredDatasets.length === 0 ? "empty-data-grid" : undefined;

    const allFilesSelected =
        selectedFiles.length === filteredDatasets.length &&
        filteredDatasets.length !== 0;

    function HandleSelectAllFiles() {
        if (allFilesSelected) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(filteredDatasets.map((file) => file.fileName));
        }
    }

    function HandleSelectFile(fileName: string, isSelected: boolean) {
        setSelectedFiles((prevSelectedFiles) => {
            if (isSelected) {
                return [...prevSelectedFiles, fileName];
            }

            return prevSelectedFiles.filter((file) => file !== fileName);
        });
    }

    function HandleOnBack() {
        setSelectedFiles([]);
        onClose();
    }

    if (className) {
        cnParts.push(className);
    }

    if (dataGridEmpty) {
        gridWrapperCnParts.push(dataGridEmpty);
    }

    const dataGridTestId = testId ? `${testId}-data-grid` : undefined;
    const dataGridRowsTestId = testId ? `${testId}-data-grid-row` : undefined;
    const closeButtonTestId = testId ? `${testId}-close` : undefined;
    const searchBarTestId = testId ? `${testId}-search-bar` : undefined;
    const backButtonTestId = testId ? `${testId}-back` : undefined;
    const addButtonTestId = testId ? `${testId}-add` : undefined;

    const gridProps = {
        data: filteredDatasets,
        columns: ColumnsRenderer(),
        useStickyHeaders: true,
        headerRenderer: () => (
            <HeaderRendererFull
                allFilesSelected={allFilesSelected}
                onSelectAllFiles={HandleSelectAllFiles}
                isDatasetsPage={false}
            />
        ),

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rowRenderer: (row: any) =>
            RowRendererFull(
                row,
                HandleSelectFile,
                selectedFiles,
                false,
                undefined,
                undefined,
                dataGridRowsTestId
            ),
        testId: dataGridTestId
    };

    const gridWrapperCn = gridWrapperCnParts.join(" ");
    const cn = cnParts.join(" ");

    return (
        <Modal
            className={cn}
            isOpen={isOpen}
            onRequestClose={onClose}
            showCloseButton={false}
            testId={testId}
        >
            <ModalHeader className="datasets-selector-modal-header">
                <h2 className="datasets-selector-modal-title">
                    Dataset Selection
                </h2>
                <Button
                    className="close-button"
                    variant="text"
                    size="large"
                    icon={<FontAwesomeIcon icon={faTimes as IconProp} />}
                    onClick={onClose}
                    testId={closeButtonTestId}
                />
            </ModalHeader>
            <ModalContent className="datasets-selector-modal-content">
                <DatasetsSearchBar
                    className="datasets-search-bar"
                    datasets={filteredDatasets as StoredDataset[]}
                    onSelectDataset={(datasetName: string) => {
                        const dataset = userUploadedDatasets?.find(
                            (d: StoredDataset) => d.fileName === datasetName
                        );
                        if (dataset) {
                            const isSelected = selectedFiles.includes(
                                dataset.fileName
                            );
                            HandleSelectFile(dataset.fileName, !isSelected);
                        }
                    }}
                    testId={searchBarTestId}
                    isLoading={isLoading}
                />
                <Card className={gridWrapperCn}>
                    <DataGrid {...gridProps} />
                    {filteredDatasets.length === 0 && <EmptySelectorGrid />}
                </Card>
            </ModalContent>
            <ModalFooter className="datasets-selector-modal-footer">
                <Button
                    className="back-button"
                    color="primary"
                    onClick={HandleOnBack}
                    size="large"
                    text="Back"
                    variant="outline"
                    testId={backButtonTestId}
                />
                <Button
                    className="add-datasets-button"
                    isDisabled={selectedFiles.length === 0}
                    color="primary"
                    onClick={() => {
                        selectedFiles.forEach((fileName: string) =>
                            onSelectDataset(fileName)
                        );
                        setSelectedFiles([]);
                        onClose();
                    }}
                    size="large"
                    text="Add"
                    variant="solid"
                    testId={addButtonTestId}
                />
            </ModalFooter>
        </Modal>
    );
}
