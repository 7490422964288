import { StoredDataset } from "@services/space/datasets/models";
import { localStorageService } from "../utilities/dataset-page/localStorageService";
import { useAuth } from "./use-auth";
import { useEffect, useState } from "react";

export function useLoadDatasetsForUser(userId: string) {
    return localStorageService.loadDatasetsForUser(userId);
}

export function useSaveDatasetsForUser(
    userId: string,

    datasets: StoredDataset[]
) {
    localStorageService.saveDatasetsForUser(userId, datasets);
}

export function useRemoveDatasetForUser(userId: string, fileName: string) {
    localStorageService.removeDatasetForUser(userId, fileName);
}

export function useUserDatasets() {
    const { currentAccount } = useAuth();

    const [datasets, setDatasets] = useState<StoredDataset[]>([]);

    useEffect(() => {
        let isActive = true;

        const loadDatasets = async () => {
            try {
                const userDatasets =
                    await localStorageService.loadDatasetsForUser(
                        currentAccount?.username as string
                    );

                if (isActive) {
                    setDatasets(userDatasets || []);
                }
            } catch (error) {
                console.error("Failed to load datasets", error);
            }
        };

        loadDatasets();

        return () => {
            isActive = false;
        };
    }, [currentAccount]);

    return datasets;
}
