import React, { useCallback } from "react";
import {
    AnalysisParameters,
    BaseAnalysisParameters
} from "../../interfaces/analysis-types";
import { useDatasetManagement } from "../../stores/datasets/datasets-context";
import isEqual from "lodash/isEqual";
import { fetchAnalysisResponse } from "./analysis-utils/fetchAnalysisResponse";
import {
    postRequestAnalysisFailure,
    requestAnalysis,
    postRequestAnalysisSuccess
} from "../../stores/datasets/datasets-actions";
import { analysisTypeToTitle } from "./getAnalysisTitle";
import { AnalysisResponseType } from "@stores/datasets/datasets-types";
import { buildAnalysisRequest } from "./analysis-utils/buildAnalysisRequest";
import {
    BaseAnalysisResponse,
    ReportFormat
} from "@services/space/analysis/models";
import { fetchAnalysisReport } from "./analysis-utils/fetchAnalysisReport";
import { downloadFile } from "../download-file/download-file";
import { spaceTrackEvent } from "../../logging/space-track-event";
import {
    DOWNLOAD_REPORT,
    ERROR_DOWNLOAD_REPORT,
    ERROR_GET_ANALYSIS_RESULTS,
    GET_ANALYSIS_RESULTS,
    UPDATE_ANALYSIS_PARAMETERS
} from "../../logging/log-events";
import { useAuth } from "../../hooks/use-auth";
import { ApiFileResponse } from "@services/api-response";

export const useAnalysisParametersHandlers = (
    analysisParams: AnalysisParameters,
    setAnalysisParams: React.Dispatch<React.SetStateAction<AnalysisParameters>>,
    onCreateReport: (reportParams: AnalysisParameters) => void,
    areParametersValid: boolean
) => {
    const { dispatch } = useDatasetManagement();
    const { currentAccount } = useAuth();
    const currentUserId = currentAccount?.username;

    const handleParametersUpdate = useCallback(
        (updates: Partial<BaseAnalysisParameters>) => {
            const analysisKey =
                `${analysisParams.analysisType}Params` as keyof AnalysisParameters;
            const currentParams = analysisParams[
                analysisKey
            ] as Partial<BaseAnalysisParameters>;
            const newParams = { ...currentParams, ...updates };

            if (!isEqual(currentParams, newParams)) {
                setAnalysisParams((prev: AnalysisParameters) => ({
                    ...prev,
                    [analysisKey]: newParams
                }));

                spaceTrackEvent({
                    type: UPDATE_ANALYSIS_PARAMETERS,
                    contents: JSON.stringify({
                        updates,
                        currentUserId
                    })
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [analysisParams, setAnalysisParams]
    );

    const handleSubmit = useCallback(async () => {
        if (areParametersValid) {
            dispatch({
                type: "SET_ANALYSIS_PARAMETERS",
                payload: analysisParams
            });
            onCreateReport(analysisParams);

            const request = buildAnalysisRequest(analysisParams);

            dispatch(requestAnalysis());

            try {
                const response =
                    await fetchAnalysisResponse<BaseAnalysisResponse>(request);

                if (response.data !== undefined) {
                    const analysisResponse: AnalysisResponseType = {
                        [analysisParams.analysisType]: response.data
                    };
                    spaceTrackEvent({
                        type: GET_ANALYSIS_RESULTS,
                        contents: JSON.stringify({
                            analysisResponse,
                            currentUserId
                        })
                    });
                    dispatch(postRequestAnalysisSuccess(analysisResponse));
                } else {
                    dispatch(postRequestAnalysisFailure(response));
                }
            } catch (error) {
                console.error(
                    `Error performing ${analysisTypeToTitle[analysisParams.analysisType]} analysis:`,
                    error
                );
                spaceTrackEvent({
                    type: ERROR_GET_ANALYSIS_RESULTS,
                    contents: JSON.stringify(error)
                });
                dispatch(
                    postRequestAnalysisFailure({
                        title: (error as Error).message
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areParametersValid, dispatch, analysisParams, onCreateReport]);

    const handleDownloadReport = useCallback(
        async (reportFormat: ReportFormat) => {
            const request = buildAnalysisRequest(analysisParams);

            try {
                const response = await fetchAnalysisReport(
                    request,
                    reportFormat
                );

                if (response.content !== undefined) {
                    spaceTrackEvent({
                        type: DOWNLOAD_REPORT,
                        contents: JSON.stringify({
                            downloadedFileName: response.filename,
                            currentUserId
                        })
                    });
                    downloadFile(response as ApiFileResponse, "");
                } else {
                    dispatch(postRequestAnalysisFailure(response));
                }
            } catch (error) {
                console.error(
                    `Error downloading ${analysisTypeToTitle[analysisParams.analysisType]} report:`,
                    error
                );
                spaceTrackEvent({
                    type: ERROR_DOWNLOAD_REPORT,
                    contents: JSON.stringify(error)
                });
                dispatch(
                    postRequestAnalysisFailure({
                        title: (error as Error).message
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, analysisParams]
    );

    return { handleParametersUpdate, handleSubmit, handleDownloadReport };
};
