import { Config } from "@config";
import {
    LogManager,
    logsAtOrAbove,
    LogLevel,
    getAppVersionFromMetaTag,
    LogEvent,
    createAppInsightsSink
} from "@cpchem/logging";
import { spaceEventSinks } from "./space-appinsights-events";

const LOGGING_CONTEXT = {
    spaceSessionId: "",
    who: ""
};
// const values for app insight filter system
const DO_NOT_SEND = false;
const APPROVED_TO_SEND = true;

export function configureLogging(config: Config): void {
    const appVersion = {
        appVersion: getAppVersionFromMetaTag()
    };

    const appInsightSettings =
        config.logging.appInsights.connectionString !== ""
            ? {
                  appInsights: {
                      connectionString:
                          config.logging.appInsights.connectionString,
                      sinkPageViews: true,
                      sinkLogsAtOrAboveLevel: LogLevel.WARN
                  }
              }
            : undefined;
    LogManager.initialize({
        ...appVersion,
        ...appInsightSettings
    });

    if (appInsightSettings) {
        const spaceSessionId = `${LogManager.appInsights().context.user.id}-${
            LogManager.appInsights().context.telemetryTrace.traceID
        }`;

        console.log(
            `This is your Space session id ${spaceSessionId}. Welcome to SPACE.`
        );

        configureAppInsightsLogging(spaceSessionId, config);
    }

    const environmentSpecificLogFilter = logsAtOrAbove(
        config.env.toUpperCase() === "DEVELOPMENT"
            ? LogLevel.INFO
            : LogLevel.ERROR
    );

    LogManager.current().subscribeToLogs(
        consoleLogger,
        environmentSpecificLogFilter
    );
}

export function configureAppInsightsLogging(
    spaceSessionId: string,
    config: Config
) {
    LOGGING_CONTEXT.spaceSessionId = spaceSessionId ?? "";
    const commonSinkBehaviors = (event: LogEvent) => {
        const eventSpecificDetails = spaceEventSinks[event.type]
            ? spaceEventSinks[event.type](event)
            : {};
        return { ...eventSpecificDetails, ...LOGGING_CONTEXT };
    };

    LogManager.current().subscribeToEvents(
        createAppInsightsSink(commonSinkBehaviors),
        (type) => spaceEventSinks[type.toUpperCase()] !== undefined
    );

    if (config.logging.logRocketAppId !== "") {
        AddLogManagerAppInsightFilter(config.logging.logRocketAppId);
    }
}

function consoleLogger(level: string, message: string): void {
    console.log(`${level}: ${message}`);
}

export function setUserInLoggingContext(user: string) {
    LOGGING_CONTEXT.who = user;
}

export function getSpaceSessionId(): string {
    return LOGGING_CONTEXT.spaceSessionId;
}

function AddLogManagerAppInsightFilter(filter: string) {
    LogManager.appInsights().appInsights.addTelemetryInitializer((item) => {
        const isFilterTargetFound =
            item?.baseData?.target !== undefined
                ? item?.baseData?.target.indexOf(encodeURIComponent(filter)) >=
                  0
                : false;

        if (isFilterTargetFound) {
            return DO_NOT_SEND;
        }
        return APPROVED_TO_SEND;
    });
}
