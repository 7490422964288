export const getTestIds = (testId: string | undefined) => ({
    selectedDatasetsTestId: testId ? `${testId}-selected-datasets` : undefined,
    selectedDatasetsBadgeTestId: testId
        ? `${testId}-selected-datasets-badge`
        : undefined,
    noSelectedDatasetsTestId: testId
        ? `${testId}-no-selected-datasets`
        : undefined,
    resinTypeTestId: testId ? `${testId}-resin-type` : undefined,
    compareDatasetsTestId: testId ? `${testId}-compare-datasets` : undefined,
    compareDatasetsCheckboxTestId: testId
        ? `${testId}-compare-datasets-checkbox`
        : undefined,
    dataTypesTestId: testId ? `${testId}-data-types` : undefined,
    dataTypesFreqSweepTestId: testId
        ? `${testId}-data-types-freq-sweep`
        : undefined,
    dataTypesTempFreqSweepTestId: testId
        ? `${testId}-data-types-temp-freq-sweep`
        : undefined,
    fixNTestId: testId ? `${testId}-fix-n` : undefined,
    fixNYesTestId: testId ? `${testId}-fix-n-yes` : undefined,
    fixNNoTestId: testId ? `${testId}-fix-n-no` : undefined,
    fixNTextFieldTestId: testId ? `${testId}-fix-n-text-field` : undefined,
    vogelTempTestId: testId ? `${testId}-vogel-temp` : undefined,
    vogelTempTextFieldTestId: testId
        ? `${testId}-vogel-temp-text-field`
        : undefined,
    referenceTempTestId: testId ? `${testId}-reference-temp` : undefined,
    referenceTempTextFieldTestId: testId
        ? `${testId}-reference-temp-text-field`
        : undefined,
    yieldStressTestId: testId ? `${testId}-yield-stress` : undefined,
    yieldStressCheckboxTestId: testId
        ? `${testId}-yield-stress-checkbox`
        : undefined
});
