import config from "@config";
import { ApiResponse, ServiceLocator } from "@services";
import {
    FetchInterceptorService,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { FetchOptionMethod, FetchOptions } from "@services/fetch-interfaces";
import { DatasetConvertService, DatasetFormatsService } from "./interface";
import { log, LogLevel } from "@cpchem/logging";
import { AuthManager } from "@cpchem/azure-auth";
import {
    ApiErrorResponse,
    errorResponseDictionary,
    errorResponseValue
} from "@services/api-response";
import { DatasetConvertResponse, DatasetFormatsResponse } from "./models";

const spaceResponseDictionary: errorResponseValue = {
    ...errorResponseDictionary
};

function defaultErrorResponse(response: Response): ApiErrorResponse {
    log(
        `Unknown error when attempting to retrieve data. Status: ${response.statusText}`,
        LogLevel.ERROR
    );
    return {
        title: response.statusText
    };
}

export class DatasetServiceImplementation
    implements DatasetFormatsService, DatasetConvertService
{
    private readonly datasetformatsBase = config.api.datasetFormats.url;
    private readonly datasetformatsScopes = config.api.datasetFormats.scopes;
    private readonly datasetConvertBase = config.api.datasetConvert.url;
    private readonly datasetConvertScopes = config.api.datasetConvert.scopes;

    interceptor: FetchInterceptorService;
    authMgr: AuthManager;

    constructor(authManager?: AuthManager) {
        this.authMgr = authManager ?? AuthManager.getInstance();
        this.interceptor =
            ServiceLocator.get<FetchInterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        scopes: string[],
        method: FetchOptionMethod,
        body?: Blob | ArrayBuffer | string | null
    ): Promise<FetchOptions> {
        return await this.interceptor.getFetchOptionsAsync(
            scopes,
            method,
            body
        );
    }

    async getAllDatasetFormats(): Promise<
        ApiResponse<DatasetFormatsResponse> | ApiErrorResponse
    > {
        const uri = `${this.datasetformatsBase}`;

        const options = await this.ensureFetchOptionsAsync(
            this.datasetformatsScopes,
            "GET"
        );

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (spaceResponseDictionary[res.status]) {
            return spaceResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async postDatasetConvert(
        uploadFile: Blob | ArrayBuffer | string | null
    ): Promise<ApiResponse<DatasetConvertResponse> | ApiErrorResponse> {
        const uri = `${this.datasetConvertBase}`;

        const options = await this.ensureFetchOptionsAsync(
            this.datasetConvertScopes,
            "POST",
            null
        );

        options.body = uploadFile;
        options.method = "POST";

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (spaceResponseDictionary[res.status]) {
            return spaceResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }
}
