import React, {
    createContext,
    useContext,
    useReducer,
    ReactNode,
    useEffect
} from "react";
import { DatasetState, DatasetAction } from "./datasets-types";
import { dataSetReducer, initialState } from "./datasets-reducer";
import { fetchRheologyAnalysisResults } from "./datasets-actions";
import { RheologyAnalysisRequest } from "@services/space/analysis/models";

interface DatasetContextType {
    state: DatasetState;
    dispatch: React.Dispatch<DatasetAction>;
    fetchRheologyAnalysisResults: (
        request: RheologyAnalysisRequest
    ) => Promise<void>;
}

const DataSetContext = createContext<DatasetContextType | undefined>(undefined);

const STORAGE_KEY = "datasetState";

export const DatasetProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(dataSetReducer, initialState);

    useEffect(() => {
        const rawData = localStorage.getItem(STORAGE_KEY);
        if (rawData) {
            dispatch({ type: "REHYDRATE_STATE", payload: JSON.parse(rawData) });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    const handleFetchRheologyAnalysisResults = async (
        request: RheologyAnalysisRequest
    ) => {
        await fetchRheologyAnalysisResults(request)(dispatch);
    };

    return (
        <DataSetContext.Provider
            value={{
                state,
                dispatch,
                fetchRheologyAnalysisResults: handleFetchRheologyAnalysisResults
            }}
        >
            {children}
        </DataSetContext.Provider>
    );
};

export const useDatasetManagement = (): DatasetContextType => {
    const context = useContext(DataSetContext);
    if (!context) {
        throw new Error(
            "useDatasetManagement must be used within a DatasetProvider"
        );
    }
    return context;
};
