import React from "react";
import { Button, Modal, ModalContent } from "@cpchem/covalence-ui";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface RemoveDatasetsModalProps {
    className?: string;
    isOpen: boolean;
    selectedDatasets: number;
    onRemoveDatasets: () => void;
    onRequestClose: () => void;
    testId?: string;
}

export function RemoveDatasetsModal({
    className,
    isOpen,
    selectedDatasets,
    onRemoveDatasets,
    onRequestClose,
    testId
}: RemoveDatasetsModalProps): JSX.Element {
    const cnParts = ["remove-datasets-modal", "space-modal"];

    if (className) {
        cnParts.push(className);
    }

    function handleRemoveFile() {
        onRemoveDatasets();
        onRequestClose();
    }

    const removeButtonTestId = testId ? `${testId}-remove` : undefined;
    const closeButtonTestId = testId ? `${testId}-close` : undefined;

    const cn = cnParts.join(" ");
    return (
        <Modal
            className={cn}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            showCloseButton={false}
            testId={testId}
        >
            <div className="modal-header">
                <div className="modal-header-title">Remove Files</div>
                <Button
                    className="modal-close-button"
                    onClick={onRequestClose}
                    variant="text"
                    icon={<FontAwesomeIcon icon={faX as IconProp} />}
                    size="large"
                    testId={closeButtonTestId}
                />
            </div>
            <ModalContent>
                <div className="remove-datasets-modal-content">
                    <div className="modal-content-title">
                        Are you sure you want to remove these files?
                    </div>
                    <div className="modal-content-text">
                        You are going to remove {selectedDatasets} files.
                    </div>
                </div>

                <div className="modal-action-buttons">
                    <Button
                        color="primary"
                        variant="outline"
                        text="Cancel"
                        onClick={onRequestClose}
                    />
                    <Button
                        className="remove-dataset"
                        color="danger"
                        variant="solid"
                        onClick={handleRemoveFile}
                        testId={removeButtonTestId}
                        text="Remove"
                    />
                </div>
            </ModalContent>
        </Modal>
    );
}
