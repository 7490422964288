export function ColumnsRenderer() {
    // onSelectAllFiles: () => void,
    // isChecked: boolean
    // const CheckboxHeader = () => (
    //     <Checkbox isChecked={isChecked} label="" onChange={onSelectAllFiles} />
    // );

    return [
        // {
        //     Header: CheckboxHeader,
        //     accessor: "fileSelection",
        //     disableSortBy: true,
        //     filterLabel: "File Selection",
        //     id: "fileSelection"
        // },
        {
            Header: "Name",
            accessor: "fileName",
            filterLabel: "Name",
            id: "fileName",
            filter: "includesValue"
        },
        {
            Header: "Type",
            accessor: "fileType",
            filterLabel: "Type",
            id: "fileType",
            filter: "includesValue"
        },
        {
            Header: "Size",
            accessor: "fileSize",
            filterLabel: "Size",
            id: "fileSize",
            filter: "includesValue"
        },
        {
            Header: "Data Format",
            accessor: "dataFormat",
            filterLabel: "Data Format",
            id: "dataFormat",
            filter: "includesValue"
        },
        {
            Header: "",
            accessor: "removeFile",
            filterLabel: "Remove File",
            id: "removeFile",
            filter: "includesValue"
        }
    ];
}
