import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@cpchem/covalence-ui";
import "./styles.scss";

export type SpaceAccordionProps = {
    className?: string;
    children: React.ReactNode;
    label: string;
    /**
     * Allows to define if the accordion will default to Open when rendered. Default value is false.
     *
     * @defaultValue false
     */
    defaultOpen?: boolean;
    isOpen?: boolean;
    testId?: string;
    onToggle?: () => void;
};

export function SpaceAccordion({
    className,
    children,
    label,
    isOpen,
    defaultOpen = false,
    testId,
    onToggle
}: SpaceAccordionProps): JSX.Element {
    const cnParts = ["space-accordion"];
    const [isAccordionOpen, setIsAccordionOpen] = useState(defaultOpen);

    useEffect(() => {
        if (isOpen !== undefined) {
            setIsAccordionOpen(isOpen);
        }
    }, [isOpen]);

    function handleToggle() {
        if (onToggle) {
            onToggle();
        }
    }

    if (className) {
        cnParts.push(className);
    }
    if (isAccordionOpen) {
        cnParts.push("open");
    }

    const AccordionIcon = isAccordionOpen ? faChevronUp : faChevronDown;

    const buttonTestId = testId ? `${testId}-button` : undefined;

    const cn = cnParts.join(" ");

    return (
        <div className={cn} data-testid={testId}>
            <div className="header">
                <Button
                    className="space-accordion-button"
                    icon={<FontAwesomeIcon icon={AccordionIcon} />}
                    isIconAfterText
                    onClick={handleToggle}
                    testId={buttonTestId}
                    text={label}
                    variant="text"
                />
            </div>
            {isAccordionOpen && <div className="content open">{children}</div>}
        </div>
    );
}
