import React from "react";
import { datasetMetaData } from "@services/space/datasets/models";

interface MetadataValuesSectionProps {
    metadata: datasetMetaData[];
    testId?: string;
}

export function MetadataValuesSection({
    metadata,
    testId
}: MetadataValuesSectionProps): JSX.Element {
    return (
        <div className="metadata-values-section" data-testid={testId}>
            <h1>Metadata</h1>
            {metadata.map((section) => (
                <div key={`section-${section.title}`}>
                    <h2>{section.title}</h2>
                    <div className="scrollable-table-container-metadata">
                        <table className="metadata-values-table">
                            <tbody>
                                {section.attributes.map((attribute) => (
                                    <tr
                                        className="metadata-values-row"
                                        key={`meta-${attribute.title}`}
                                    >
                                        <th className="metadata-values-row-title">
                                            {attribute.title}
                                        </th>
                                        <td className="metadata-values-row-value">
                                            {attribute.value}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
}
