import { DatasetState, DatasetAction } from "./datasets-types";

export const initialState: DatasetState = {
    datasetFormats: [],
    convertData: null,
    userDatasets: [],
    loading: false,
    analysisParameters: null,
    cyRheologyParameters: null,
    error: null,
    analysisResponse: null
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidDatasetState(payload: any): payload is DatasetState {
    return (
        payload && typeof payload === "object" && "datasetFormats" in payload
    );
}

const datasetReducerActions: Record<
    string,
    (state: DatasetState, action: DatasetAction) => DatasetState
> = {
    SET_DATA_FORMATS: (state, action) => ({
        ...state,
        datasetFormats: action.type === "SET_DATA_FORMATS" ? action.payload : []
    }),
    SET_USER_DATASETS: (state, action) => ({
        ...state,
        userDatasets: action.type === "SET_USER_DATASETS" ? action.payload : []
    }),
    SET_ANALYSIS_PARAMETERS: (state, action) => {
        if (action.type === "SET_ANALYSIS_PARAMETERS") {
            return {
                ...state,
                analysisParameters: action.payload
            };
        }
        return state;
    },
    SET_CY_RHEOLOGY_PARAMETERS: (state, action) => {
        if (action.type === "SET_CY_RHEOLOGY_PARAMETERS") {
            return {
                ...state,
                cyRheologyParameters: action.payload
            };
        }
        return state;
    },
    RESET_DATA_FORMATS: (state) => ({
        ...state,
        datasetFormats: []
    }),
    REQUEST_CONVERT_DATASET: (state) => ({
        ...state,
        loading: true,
        error: null
    }),
    POST_CONVERT_DATASET_SUCCESS: (state, action) => ({
        ...state,
        convertData:
            action.type === "POST_CONVERT_DATASET_SUCCESS"
                ? action.payload
                : null,
        loading: false,
        error: null
    }),
    POST_CONVERT_DATASET_FAILURE: (state, action) => ({
        ...state,
        convertData: null,
        error:
            action.type === "POST_CONVERT_DATASET_FAILURE"
                ? action.payload
                : { title: "An error occurred" },
        loading: false
    }),
    POST_FETCH_LOCAL_USER_DATASETS_FAILURE: (state, action) => ({
        ...state,
        userDatasets: [],
        error:
            action.type === "POST_FETCH_LOCAL_USER_DATASETS_FAILURE"
                ? action.payload
                : { title: "An error occured getting local user datasets" },
        loading: false
    }),
    CLEAR_USER_DATASETS: (state) => ({
        ...state,
        userDatasets: []
    }),
    REMOVE_USER_DATASET: (state, action) => ({
        ...state,
        userDatasets: state.userDatasets.filter(
            (dataset) => dataset.fileName !== action.payload
        )
    }),
    REHYDRATE_STATE: (state, action) => {
        return isValidDatasetState(action.payload)
            ? { ...state, ...action.payload }
            : state;
    },
    REQUEST_ANALYSIS: (state) => ({
        ...state,
        loading: true
    }),
    POST_REQUEST_ANALYSIS_SUCCESS: (state, action) => {
        return {
            ...state,
            analysisResponse: {
                ...(state.analysisResponse || {}),
                ...(action.payload as object)
            },
            loading: false,
            error: null
        };
    },
    POST_REQUEST_ANALYSIS_FAILURE: (state, action) => ({
        ...state,
        loading: false,
        error:
            action.type === "POST_REQUEST_ANALYSIS_FAILURE"
                ? action.payload
                : { title: "An error occurred" }
    })
};

export const dataSetReducer = (
    state: DatasetState,
    action: DatasetAction
): DatasetState => {
    const actionHandler = datasetReducerActions[action.type];
    return actionHandler ? actionHandler(state, action) : state;
};
