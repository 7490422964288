import React from "react";
import "./styles.scss";
import { spaceTrackEvent } from "../../logging/space-track-event";
import { ERROR_UNAUTHENTICATED_USER } from "../../logging/log-events";

export function UnauthenticatedPage(): JSX.Element {
    spaceTrackEvent({
        type: ERROR_UNAUTHENTICATED_USER,
        contents: "Unauthenticated user"
    });
    return (
        <div className="unauthenticated-page">
            <p>It appears your login has failed.</p>
        </div>
    );
}
