import React from "react";
import { faFolderOpen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./empty-grid-styles.scss";

export function EmptyGrid(): JSX.Element {
    const headerText = "No Datasets Have Been Uploaded.";
    const subText = `Drag and Drop files or Click "Upload" to Upload Data.`;
    return (
        <div className="empty-grid">
            <div className="image">
                <FontAwesomeIcon icon={faFolderOpen as IconProp} />
            </div>
            <h1 className="empty-heading">{headerText}</h1>
            <span className="empty-sub-text">{subText}</span>
        </div>
    );
}
